import React from 'react';
import Button, { BUTTON_SIZES } from '../Button/Button';
import { useNavigate } from 'react-router-dom';
import styles from './AppBar.scss';
import { FlexSpace, TightSpace } from '../Space/Space';

const AppBar = ({ auth, title, logo, children }) => {
  console.log('CHILDREN', children);
  return (
    <div className={styles.Container}>
      {!!logo && (
        <div
          className={styles.Logo}
          onClick={() => {
            window.location.href = '/';
          }}
        >
          {logo}
        </div>
      )}
      {!!title && (
        <div
          className={styles.Title}
          onClick={() => {
            window.location.href = '/';
          }}
        >
          {title}
        </div>
      )}

      {children}
      <FlexSpace />
      <Button
        onClick={() => {
          auth.logout();
        }}
      >
        SAIR
      </Button>
    </div>
  );
};

export default AppBar;
