import { domoInstance, Icon } from '@acmos/ui';
import styles from './Importance.module.scss';

const Importance = ({ entity }) => {
  const setImportance = (level) => (e) => {
    entity.importance = level;
    entity.save(domoInstance.db);
  };
  return (
    <div className={styles.RequirementImportance}>
      <div
        onClick={setImportance(1)}
        className={[
          styles.ImportanceLevel,
          entity.importance > 0 ? styles.Active : '',
          styles.VeryLow,
        ].join(' ')}>
        <Icon icon="bookmark" extraClass={styles.Icon} />
      </div>
      <div
        onClick={setImportance(2)}
        className={[
          styles.ImportanceLevel,
          entity.importance > 1 ? styles.Active : '',
          styles.Low,
        ].join(' ')}>
        <Icon icon="bookmark" extraClass={styles.Icon} />
      </div>
      <div
        onClick={setImportance(3)}
        className={[
          styles.ImportanceLevel,
          entity.importance > 2 ? styles.Active : '',
          styles.Normal,
        ].join(' ')}>
        <Icon icon="bookmark" extraClass={styles.Icon} />
      </div>
      <div
        onClick={setImportance(4)}
        className={[
          styles.ImportanceLevel,
          entity.importance > 3 ? styles.Active : '',
          styles.High,
        ].join(' ')}>
        <Icon icon="bookmark" extraClass={styles.Icon} />
      </div>
      <div
        onClick={setImportance(5)}
        className={[
          styles.ImportanceLevel,
          entity.importance > 4 ? styles.Active : '',
          styles.Critical,
        ].join(' ')}>
        <Icon icon="bookmark" extraClass={styles.Icon} />
      </div>
    </div>
  );
};

export default Importance;
