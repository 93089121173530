import { httpsCallable } from 'firebase/functions';
import cloudInstance from './cloud-firebase-client';
import domoInstance from './domo-firebase-client';
import formworkInstance from './formwork-firebase-client';

const _getCompany = httpsCallable(cloudInstance.functions, 'getCompany');
const _listCompanies = httpsCallable(cloudInstance.functions, 'listCompanies');
const _createCompany = httpsCallable(cloudInstance.functions, 'createCompany');
const _updateCompany = httpsCallable(cloudInstance.functions, 'updateCompany');
const _createUser = httpsCallable(cloudInstance.functions, 'createUser');
const _updateUser = httpsCallable(cloudInstance.functions, 'updateUser');

const getCompany = (data) => {
  return _getCompany(data).then((o) => o.data);
};
const listCompanies = () => {
  return _listCompanies().then((o) => o.data);
};
const createCompany = (data) => {
  return _createCompany(data).then((o) => o.data);
};
const updateCompany = (data) => {
  return _updateCompany(data).then((o) => o.data);
};
const createUser = (data) => {
  return _createUser(data).then((o) => o.data);
};
const updateUser = (data) => {
  return _updateUser(data).then((o) => o.data);
};

export const APPS = {
  DOMO: 'domo',
  CLOUD: 'cloud',
};

export default {
  cloud: {
    getCompany,
    listCompanies,
    createCompany,
    updateCompany,
    createUser,
    updateUser,
  },
};
