"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _User = _interopRequireDefault(require("./User.js"));

var _Company = _interopRequireDefault(require("./Company.js"));

var _Group = _interopRequireDefault(require("./Group.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var models = {
  User: _User["default"],
  Company: _Company["default"],
  Group: _Group["default"]
};
var _default = models;
exports["default"] = _default;