import React, { Fragment, useState, useEffect, useRef } from 'react';
import Icon from '../Icon/Icon';

import Text from '../Text/Text';
import styles from './Input.scss';

export const INPUT_SIZES = {
  SMALL: 'Small',
  NORMAL: 'Normal',
  BIG: 'Big',
};

const Input = ({
  value,
  label,
  leftIcon,
  rightIcon,
  items,
  itemRender,
  itemDisplayProps,
  selectedItemProp,
  containerExtraClass,
  invalidClass,
  validation,
  size,
  interceptValueChange,
  onChange,
  onLeftIconClick,
  onRightIconClick,
  ...rest
}) => {
  const [isValid, setIsValid] = useState(true);
  const [opened, setOpened] = useState(false);
  const [_value, _setValue] = useState('');
  const [_text, _setText] = useState('');
  const inputRef = useRef();
  const blurTimeout = useRef();
  const itemListRef = useRef();

  useEffect(() => {
    if (items) {
      _setValue(getValueBasedOnSelected(getValue()));
      return;
    }
    _setValue(value);
  }, [value, items]);

  useEffect(() => {
    if (!opened || !itemListRef.current) return;
    let rect = itemListRef.current.getBoundingClientRect();
    if (rect.top + 300 > window.innerHeight) {
      itemListRef.current.classList.add(styles.FromBottom);
    } else {
      itemListRef.current.classList.remove(styles.FromBottom);
    }
  }, [itemListRef.current, opened]);

  const getValue = () => {
    let _value = value;
    if (selectedItemProp) {
      _value = items.find((o) => o[selectedItemProp] === value);
    }
    return _value;
  };

  const getValueFromItem = (item) => {
    let v = item;
    if (selectedItemProp) {
      v = item?.[selectedItemProp];
    } else if (interceptValueChange) {
      v = interceptValueChange(item);
    }
    return v;
  };

  const getValueBasedOnSelected = (item, component) => {
    if (!itemDisplayProps) {
      if (selectedItemProp) {
        return item?.[selectedItemProp];
      }
      return typeof item === 'string' ? item : JSON.stringify(item);
    }
    if (typeof itemDisplayProps === 'function') {
      return itemDisplayProps(item);
    }

    let text = itemDisplayProps?.map((prop) => {
      if (component) {
        return (
          <span>
            {typeof item === 'string'
              ? item
              : item?.[prop] || (item ? prop : '')}
          </span>
        );
      }
      return item?.[prop] || (item ? prop : '');
    });
    if (component) return text;

    return text?.join(' ').trim();
  };

  const _onChange = (e) => {
    let _textValue = e.target.value;
    e.target.value = interceptValueChange
      ? interceptValueChange(e.target.value)
      : e.target.value;

    if (typeof validation === 'function') {
      setIsValid(!validation(e.target.value));
    }

    _setText(_textValue);
    _setValue(e.target.value);
    if (items) {
      if (!_textValue?.trim()) {
        onChange?.({ target: { value: null } });
      }
      return;
    }
    onChange?.(e);
  };

  const _renderItem = (item) => {
    if (itemRender) {
      return itemRender(item);
    }
    return (
      <div className={styles.ItemContent}>
        {getValueBasedOnSelected(item, true)}
      </div>
    );
  };

  return (
    <div
      className={[
        styles.Container,
        leftIcon ? styles.WithLeftIcon : '',
        rightIcon ? styles.WithRightIcon : '',
        items?.length > 0 ? styles.WithItems : '',
        size ? styles['Size' + size] : '',
        containerExtraClass || '',
        typeof validation === 'function' && !isValid
          ? invalidClass || styles.Invalid
          : '',
      ].join(' ')}
    >
      {!!leftIcon && (
        <div
          className={[
            styles.LeftIcon,
            onLeftIconClick ? styles.Clickable : '',
          ].join(' ')}
          onClick={onLeftIconClick}
        >
          <Icon icon={leftIcon} />
        </div>
      )}
      <input
        ref={inputRef}
        placeholder=' '
        value={_value}
        onChange={_onChange}
        {...rest}
        onFocus={(e) => {
          setOpened(!opened);
          e.stopPropagation();
          clearTimeout(blurTimeout.current);
        }}
        onClick={() => {
          inputRef.current?.focus();
        }}
        onBlur={() => {
          clearTimeout(blurTimeout.current);
          blurTimeout.current = setTimeout(() => {
            setOpened(false);
            _setText('');
          }, 100);
        }}
      />
      {!!label && <Text extraClass={styles.Label}>{label}</Text>}
      {!!rightIcon && (
        <div
          className={[
            styles.RightIcon,
            onRightIconClick ? styles.Clickable : '',
          ].join(' ')}
          onClick={onRightIconClick}
        >
          <Icon icon={rightIcon} />
        </div>
      )}
      {items?.length > 0 && (
        <>
          <div
            className={styles.SelectArrow}
            onClick={() => {
              inputRef.current?.focus();
            }}
          >
            <Icon icon='caret-down' />
          </div>
          <div
            ref={itemListRef}
            className={[styles.Items, opened ? styles.Visible : ''].join(' ')}
          >
            {items
              .filter((item) => {
                if (!_text) return true;
                let valueString = getValueBasedOnSelected(item);
                return (
                  valueString.toLowerCase().indexOf(_text.toLowerCase()) > -1
                );
              })
              .map((item, i) => {
                return (
                  <div
                    key={i}
                    className={styles.Item}
                    onClick={() => {
                      setOpened(false); //closes tooltip
                      _setValue(getValueBasedOnSelected(item)); //sets input texts
                      _setText(''); //reset search
                      onChange?.(getValueFromItem(item)); //call external onchange with the final expected value
                    }}
                  >
                    {_renderItem(item)}
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default Input;
