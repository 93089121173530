"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _Requirement = _interopRequireDefault(require("./Requirement.js"));

var _Project = _interopRequireDefault(require("./Project.js"));

var _Activity = _interopRequireDefault(require("./Activity.js"));

var _Phase = _interopRequireDefault(require("./Phase.js"));

var _ResourceType = _interopRequireDefault(require("./ResourceType.js"));

var _Sprint = _interopRequireDefault(require("./Sprint.js"));

var _Discussion = _interopRequireDefault(require("./Discussion.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var models = {
  Requirement: _Requirement["default"],
  Project: _Project["default"],
  Activity: _Activity["default"],
  Phase: _Phase["default"],
  ResourceType: _ResourceType["default"],
  Sprint: _Sprint["default"],
  Discussion: _Discussion["default"]
};
var _default = models;
exports["default"] = _default;