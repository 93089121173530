"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  Cloud: {
    UsersList: 1,
    UsersCreate: 2,
    UsersEdit: 3,
    UsersDelete: 4,
    CompanyList: 5,
    CompanyCreate: 6,
    CompanyEdit: 7,
    CompanyDelete: 8,
    RoleList: 9,
    RoleCreate: 10,
    RoleEdit: 11,
    RoleDelete: 12
  }
};
exports["default"] = _default;