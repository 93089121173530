import { domoInstance, TabContent, useDragAndDrop } from '@acmos/ui';
import { DomoModels } from '@acmos/models';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { i18nContext } from '../../../i18n';
import { ProjectContext } from '../Project';
import styles from './Board.module.scss';

const Board = ({ active }) => {
  const [activities, setActivities] = useState([]);
  const [columnRefs, setColumnRefs] = useState([]);
  const { i18n } = useContext(i18nContext);
  const { sprints, project, requirements } = useContext(ProjectContext);

  const { createDraggable } = useDragAndDrop();

  const activeSprints = useMemo(() => {
    return sprints.filter((o) => o.active);
  }, [sprints]);

  useEffect(() => {
    if (!project?.columns) return;
    setColumnRefs(project.columns.map((c) => React.createRef()));
  }, [project.columns]);

  useEffect(() => {
    if (!project?.id || !activeSprints.length) return;
    return DomoModels.Activity.subscribeAll(
      domoInstance.db,
      [
        ['projectId', '==', project.id],
        ['sprintId', 'in', activeSprints.map((o) => o.id)],
        ['status', '!=', 'closed'],
      ],
      (list) => {
        setActivities(list.sortByKey((o) => [o.order, o.importance, o.title]));
      }
    );
  }, [project.id, activeSprints]);

  const onDragEnd =
    (activity) =>
    ({ droppableIndex }) => {
      activity.status = project.columns[droppableIndex];
      activity.save(domoInstance.db);
    };

  return (
    <TabContent active={active} extraClass={styles.Board}>
      <div className={styles.Columns}>
        {project.columns?.map((column, i) => {
          return (
            <div
              key={column.id}
              className={styles.Column}
              style={{
                width: window.innerWidth / project.columns.length + '%',
              }}>
              <div className={styles.ColumnHeader}>
                {column.title?.trim() || i18n(column.status)}
              </div>
              <div
                className={styles.Activities}
                ref={columnRefs[i]}
                id={'content-' + column.id}
                key={'content-' + column.id}>
                {activities
                  .filter((a) => {
                    if (
                      !a.status?.id &&
                      column.status === DomoModels.Activity.DEFAULT_ACTIVITY_STATUS.todo
                    ) {
                      a.status = column;
                    }
                    return a.status?.id === column.id;
                  })
                  .map((activity) => {
                    return (
                      <div
                        key={activity.id}
                        className={styles.Card}
                        {...createDraggable({
                          sortingOptions: {
                            droppableRefs: columnRefs,
                            placeholderClass: styles.Placeholder,
                            droppableHoverClass: styles.HoverDroppable,
                            preventDomUpdateOnItem: true,
                          },
                          onDragEnd: onDragEnd(activity),
                          gripSelector: styles.DragGrip,
                        })}>
                        <div className={styles.Title}>{activity.title}</div>
                        <div className={styles.Info}>
                          <div className={styles.Number}>{activity.number}</div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    </TabContent>
  );
};

export default Board;
