import React from 'react'
import styles from './Tab.scss'
const Tab = ({ active, children, ...rest }) => {
  return (
    <div
      className={[styles.Tab, active ? styles.Active : ''].join(' ')}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Tab
