import { DomoModels, UOMList } from '@acmos/models';
import { Button, domoInstance, Input, TabContent, Text, TEXT_SIZES, TightSpace } from '@acmos/ui';
import { useContext, useRef } from 'react';
import { ProjectContext } from '../Project';

import styles from './Admin.module.scss';

const Admin = ({ active }) => {
  const resourceTypesTimeoutsRef = useRef({});
  const { project, resourceTypes } = useContext(ProjectContext);

  const addResourceType = () => {
    const resourceType = new DomoModels.ResourceType();
    resourceType.projectId = project.id;
    resourceType.company = project.company;
    resourceType.save(domoInstance.db);
  };

  const onResourceTypeChange = (resourceType, prop) => (e) => {
    resourceType[prop] =
      typeof e === 'string' ? e : e?.target ? e.target.value || e.target?.innerHTML : e;
    clearTimeout(resourceTypesTimeoutsRef.current[resourceType.id]);
    resourceTypesTimeoutsRef.current[resourceType.id] = setTimeout(() => {
      resourceType.company = project.company;
      resourceType.save(domoInstance.db);
    }, 800);
  };

  const deleteResourceType = (resourceType) => () => {
    resourceType.delete(domoInstance.db);
  };

  return (
    <TabContent active={active}>
      <div className={styles.Content}>
        <div className={styles.Users}>
          <Text size={TEXT_SIZES.NORMAL}>
            Tipos de Recurso
            <TightSpace />
            <Button onClick={addResourceType}>Adicionar no Projeto</Button>
          </Text>
        </div>
        <div className={styles.ResourceTypesList}>
          {resourceTypes.map((resourceType) => {
            return (
              <div className={styles.ResourceType} key={resourceType.id}>
                <Input
                  label="Título"
                  value={resourceType.title}
                  onChange={onResourceTypeChange(resourceType, 'title')}
                  containerExtraClass={styles.TitleInput}
                />
                <Input
                  label="Unidade de Medida"
                  value={resourceType.uom}
                  onChange={onResourceTypeChange(resourceType, 'uom')}
                  items={UOMList}
                  itemDisplayProps={['description', ' (', 'abbreviation', ')']}
                  containerExtraClass={styles.UOMInput}
                />
                <Input
                  label="Custo por Unidade"
                  value={resourceType.estimatedCostPerUom}
                  onChange={onResourceTypeChange(resourceType, 'estimatedCostPerUom')}
                  containerExtraClass={styles.UOMCostInput}
                />
                <Button onClick={deleteResourceType(resourceType)}>Excluir</Button>
              </div>
            );
          })}
        </div>
      </div>
    </TabContent>
  );
};

export default Admin;
