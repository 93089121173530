import React, { Fragment, useEffect, useState } from 'react';
import styles from './TabContent.scss';
const TabContent = ({ active, extraClass, children, ...rest }) => {
  const [visible, setVisible] = useState(null);

  useEffect(() => {
    if (active !== visible) setVisible(active);
  }, [active]);

  if (visible === null) return <></>;
  return (
    <div
      className={[
        styles.TabContent,
        !visible ? styles.Hidden : '',
        extraClass || '',
      ].join(' ')}
      {...rest}
    >
      {children}
    </div>
  );
};

export default TabContent;
