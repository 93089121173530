import {
  Button,
  BUTTON_COLORS,
  BUTTON_SIZES,
  ExtraTightSpace,
  Icon,
  Input,
  Text,
  TightSpace,
  domoInstance,
  BaseSpace,
} from '@acmos/ui';
import { DomoModels } from '@acmos/models';
import { useContext, useEffect, useState } from 'react';
import { ToastContext } from '../../../App';
import { i18nContext } from '../../../i18n';
import EditableEntityProp from '../components/EditableEntityProp';
import { ProjectContext } from '../Project';
import styles from './Phases.module.scss';
import Sprints from './Sprints';

const Phase = ({ auth, project, phase }) => {
  const [isOpened, setIsOpened] = useState(false);

  const { i18n, format } = useContext(i18nContext);
  const { requirements } = useContext(ProjectContext);
  const { addToast } = useContext(ToastContext);

  useEffect(() => {
    let programmedEffort = 0;
    let executedEffort = 0;
    if (!phase.programmedEffort) phase.programmedEffort = 0;
    if (!phase.executedEffort) phase.executedEffort = 0;

    requirements.forEach((requirement) => {
      console.log('PHASE', requirement.phase, phase.id, requirement.estimate);
      if (requirement.phase !== phase.id) return;
      if (requirement.status === 'project.requirement.status.completed') {
        executedEffort += parseFloat(requirement.estimate || 0);
      } else {
        programmedEffort += parseFloat(requirement.estimate || 0);
      }
    });

    console.log(
      'EFFORTRS',

      executedEffort,
      programmedEffort
    );
    if (phase.programmedEffort !== programmedEffort || phase.executedEffort !== executedEffort) {
      phase.programmedEffort = programmedEffort;
      phase.executedEffort = executedEffort;
      phase.save(domoInstance.db);
    }
  }, [requirements, phase]);

  const onPhaseChange = (prop) => (e) => {
    phase[prop] = typeof e === 'string' ? e : e.target?.value || e.target?.innerHTML || '';
    phase.save(domoInstance.db);
  };

  const remove = () => {
    if (requirements.find((o) => o.phase === phase.id)) {
      addToast(i18n('project.phase.message.cantDeleteHasRequirements'));
      return;
    }
    phase.delete(domoInstance.db);
  };

  return (
    <>
      <tr className={styles.ContentTR}>
        <td
          className={[styles.MinimumWidth, styles.Toggler].join(' ')}
          onClick={() => setIsOpened(!isOpened)}>
          <Icon icon="angle-down" />
        </td>
        <td>
          <EditableEntityProp auth={auth} value={phase.title} onChange={onPhaseChange('title')} />
        </td>

        <td className={styles.MinimumWidth}>
          <Input
            value={phase.dateStart}
            type="date"
            onChange={onPhaseChange('dateStart')}
            containerExtraClass={styles.InputDate}
          />
        </td>
        <td className={styles.MinimumWidth}>
          <Input
            value={phase.dateEnd}
            type="date"
            onChange={onPhaseChange('dateEnd')}
            containerExtraClass={styles.InputDate}
          />
        </td>
        <td className={styles.Actions}>
          <Button color={BUTTON_COLORS.RED} leftIcon="trash" onClick={remove} round />
        </td>
      </tr>
      {isOpened && (
        <tr className={[styles.ContentTR, styles.PhaseDetails].join(' ')}>
          <td colSpan={5}>
            <div className={styles.PhaseDetailsContent}>
              <div className={styles.PhaseDescription}>
                <Text>{i18n('project.phase.fields.description')}</Text>
                <ExtraTightSpace />

                <EditableEntityProp
                  auth={auth}
                  value={phase.description}
                  onChange={onPhaseChange('description')}
                  extraClass={styles.DescriptionEditable}
                />
                <div className={styles.FlexRow}>
                  <div>
                    <TightSpace />
                    <Text>{i18n('project.phase.fields.sprintSize')}</Text>
                    <ExtraTightSpace />
                    <Input
                      value={phase.sprintSize}
                      interceptValueChange={(value) => parseInt(value.replace(/[^\d]/gi, '') || 1)}
                      onChange={onPhaseChange('sprintSize')}
                      containerExtraClass={styles.FormInput}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.PhaseForm}>
                <Text>
                  {i18n('project.phase.fields.programmedEffort')}: {phase.programmedEffort || 0}
                </Text>
                <ExtraTightSpace />
                <Text>
                  {i18n('project.phase.fields.executedEffort')}: {phase.executedEffort || 0}
                </Text>
                <ExtraTightSpace />
                {/* 
                TODO - MODIFICAR O VALOR HORA (160) PARA CONFIGURACAO NO PROJETO
                TODO - CRIAR UMA TABELA DE UNIDADE DE MEDIDA X PRECO
                TODO - DEFINIR QUAL UNIDADE DE MEDIDA CORRESPONDE AS ESTIMATIVAS NOS ITENS
                */}
                <Text>
                  {i18n('project.phase.fields.estimatedCost')}:{' '}
                  {format.currency((phase.programmedEffort || 0) * 160)}
                </Text>
                <BaseSpace />

                <Text>{i18n('project.phase.fields.budget')}</Text>
                <ExtraTightSpace />
                <Input
                  value={phase.budget}
                  interceptValueChange={(value) => value.replace(/[^\d]/gi, '')}
                  onChange={onPhaseChange('budget')}
                  containerExtraClass={styles.FormInput}
                />

                <Text>{i18n('project.phase.fields.expectedCompletionPercent')}</Text>
                <ExtraTightSpace />
                <Input
                  value={phase.expectedCompletionPercent}
                  interceptValueChange={(value) => value.replace(/[^\d]/gi, '')}
                  onChange={onPhaseChange('expectedCompletionPercent')}
                  containerExtraClass={styles.FormInput}
                />

                <Text>{i18n('project.phase.fields.expectedCompletionDescription')}</Text>
                <ExtraTightSpace />
                <EditableEntityProp
                  auth={auth}
                  value={phase.expectedCompletionDescription}
                  onChange={onPhaseChange('expectedCompletionDescription')}
                  extraClass={[styles.FormInput, styles.Foundation].join(' ')}
                />
              </div>
            </div>
            <TightSpace />
            <Sprints auth={auth} phase={phase} />
          </td>
        </tr>
      )}
    </>
  );
};

export default Phase;
