import { useEffect, useRef } from 'react';
import styles from './EditableEntityProp.module.scss';

const EditableEntityProp = ({
  auth,
  value,
  touching,
  onChange,
  onFocus,
  onBlur,
  singleLine,
  extraClass,
}) => {
  const elementRef = useRef(null);
  const defaultValue = useRef(value);
  const typedValue = useRef(value);
  useEffect(() => {
    console.log('VALUE-TESTE', value !== elementRef.current?.innerHTML);
    if (value !== elementRef.current?.innerHTML && document.activeElement !== elementRef.current) {
      defaultValue.current = value;
      elementRef.current.innerHTML = value;
    }
  }, [value]);

  const handleInput = (event) => {
    let html = event.target.innerHTML;
    if (singleLine) {
      html = html
        .replace(/(<br>|<p>|<\/p>)/gim, '')
        .replace(/<(\/?)(div|p|block)\b>/gim, ' <$1span>');
    }
    typedValue.current = html;
    onChange?.(html);
  };

  const usersTouching =
    Object.values(touching || {})?.filter((user) => {
      return user?.uid !== auth?.currentUser?.uid;
    }) || [];

  return (
    <>
      {usersTouching.length > 0 && (
        <div className={styles.UsersTouching}>
          <div className={styles.UserTouching}>Outros usuários editando</div>
          {/* {usersTouching.map((touch) => {
            return <div className={styles.UserTouching}>{touch.user.email}</div>;
          })} */}
        </div>
      )}
      <div
        onKeyDown={(e) => {
          if (singleLine && e.key === 'Enter') {
            e.preventDefault();
            return false;
          }
          if (e.key === 'Backspace') {
            if (!elementRef.current?.innerText?.trim()) {
              elementRef.current.innerHTML = ''; //Clear unwanted styles left by the previous paste code
            }
          }
          if (!singleLine && e.key === 'Tab') {
            // elementRef.current.innerHTML+='\t';
            // elementRef.current.setRangeText('\t');
            let span = document.createElement('span');
            span.innerHTML = '&emsp;';
            insertTextAtCaret(span);
            e.preventDefault();
            return false;
          }
        }}
        ref={elementRef}
        onFocus={onFocus}
        onBlur={onBlur}
        contentEditable
        onInput={handleInput}
        className={[styles.EditingContent, extraClass || ''].join(' ')}
        dangerouslySetInnerHTML={{ __html: defaultValue.current }}></div>
    </>
  );
};

function insertTextAtCaret(node) {
  var sel, range;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0);
      range.deleteContents();
      range.insertNode(node);
      range.collapse(false);
    }
  }
}

export default EditableEntityProp;
