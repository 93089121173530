import {
  BaseSpace,
  domoInstance,
  Button,
  BUTTON_SIZES,
  Icon,
  Text,
  TEXT_SIZES,
  TightSpace,
} from '@acmos/ui';
import { DomoModels } from '@acmos/models';
import { useContext } from 'react';
import { i18nContext } from '../../../i18n';

import styles from './ActivitiesList.module.scss';
import Activity from './Activity';

const ActivitiesList = ({ auth, project, requirement, activities }) => {
  const { i18n } = useContext(i18nContext);
  const authState = auth.useAuthStore((s) => s);

  const addActivity = () => {
    project.taskCount++;
    project.save(domoInstance.db);

    const activity = new DomoModels.Activity();
    activity.company = authState.currentCompany.id;
    activity.number = project.taskCount;
    activity.projectId = project.id;
    activity.company = project.company;
    activity.requirementId = requirement.id;
    activity.save(domoInstance.db);
  };

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <Text size={TEXT_SIZES.NORMAL}>
          <Icon icon="list-check" />
          <TightSpace />
          Atividades
        </Text>
        <BaseSpace />
        <Button leftIcon="plus" onClick={addActivity}>
          Nova Atividade
        </Button>
      </div>
      <table className={styles.List}>
        <thead>
          <tr>
            <th className={styles.NumberContainer}>{i18n('project.activity.fields.number')}</th>
            <th className={styles.Title}>{i18n('project.activity.fields.title')}</th>
            <th className={styles.Description}>{i18n('project.activity.fields.description')}</th>
            <th className={[styles.Status, styles.ActionsCell].join(' ')}>
              {i18n('project.phase.sprints.sprintWord')}
            </th>
            <th className={[styles.Sprint, styles.ActionsCell].join(' ')}>
              {i18n('project.phase.sprints.status')}
            </th>
            <th className={styles.Importance}>{i18n('project.activity.fields.importance')}</th>
            <th className={styles.ActionsCell}></th>
          </tr>
        </thead>
        <tbody>
          {activities.map((activity) => {
            return (
              <Activity
                key={activity.id}
                auth={auth}
                activity={activity}
                requirement={requirement}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ActivitiesList;
