import React, { useContext, useEffect, useState } from 'react';
import { domoInstance, Content, TabbedPanel, Tab, TabList } from '@acmos/ui';
import { DomoModels } from '@acmos/models';

import styles from './Project.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { i18nContext } from '../../i18n';

import Requirements from './Requirements/Requirements';
import Admin from './Admin/Admin';
import ProjectConfig from './ProjectConfig/ProjectConfig';
import Board from './Board/Board';

const tabList = {
  project: { title: 'project.tabs.project' },
  requirements: { title: 'project.tabs.requirements' },
  board: { title: 'project.tabs.board' },
  // docs: { title: 'project.tabs.docs' },
  admin: { title: 'project.tabs.admin', admin: true },
};

export const ProjectContext = React.createContext({
  project: {},
  phases: [],
  requirements: [],
  sprints: [],
  resourceTypes: [],
  setPhases: () => {},
  setRequirements: () => {},
  setSprints: () => {},
  setResourceTypes: () => {},
});

const Project = ({ auth }) => {
  const [currentTab, setCurrentTab] = useState(tabList.project);
  const [project, setProject] = useState({});
  const [phases, setPhases] = useState([]);
  const [sprints, setSprints] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [resourceTypes, setResourceTypes] = useState([]);
  const navigate = useNavigate();

  const params = useParams();
  const { i18n } = useContext(i18nContext);

  useEffect(() => {
    return DomoModels.Project.subscribe(domoInstance.db, params.id, setProject);
  }, [params.id]);

  useEffect(() => {
    setCurrentTab(tabList[params.tab]);
  }, [params.tab]);

  useEffect(() => {
    if (!project?.id) return;
    return DomoModels.Sprint.subscribeAll(
      domoInstance.db,
      [
        ['projectId', '==', project.id],
        ['active', '==', true],
      ],
      (list) => {
        setSprints(list.sortByKey((item) => [item.dateStart, item.createdAt]));
      }
    );
  }, [project.id]);

  useEffect(() => {
    if (!project?.id) return;
    return DomoModels.Requirement.subscribeAll(
      domoInstance.db,
      [['projectId', '==', project.id]],
      (list) => {
        setRequirements(
          list.sort((a, b) => {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
          })
        );
      }
    );
  }, [project.id]);

  useEffect(() => {
    if (!project?.id) return;
    return DomoModels.Phase.subscribeAll(
      domoInstance.db,
      [['projectId', '==', project.id]],
      (list) => {
        setPhases(
          list.sort((a, b) => {
            if (a.createdAt < b.createdAt) return -1;
            if (a.createdAt > b.createdAt) return 1;
            return 0;
          })
        );
      }
    );
  }, [project.id]);

  useEffect(() => {
    if (!project?.id) return;
    return DomoModels.ResourceType.subscribeAll(
      domoInstance.db,
      [['projectId', '==', project.id]],
      (list) => {
        setResourceTypes(list.sortByKey((item) => [item.title, item.description]));
      }
    );
  }, [project.id]);

  return (
    <ProjectContext.Provider
      value={{
        project,
        phases,
        requirements,
        sprints,
        resourceTypes,
        setPhases,
        setRequirements,
        setSprints,
        setResourceTypes,
      }}>
      <Content extraClass={styles.Content}>
        <TabbedPanel extraClass={styles.Panel}>
          <TabList>
            {Object.keys(tabList).map((tabId) => {
              let tab = tabList[tabId];

              return (
                <Tab
                  key={tabId}
                  active={tab === currentTab}
                  onClick={() => {
                    setCurrentTab(tab);
                    navigate('/project/' + tabId + '/' + project.id);
                  }}>
                  {i18n(tab.title)} {tab === tabList.requirements && <>({requirements.length})</>}
                </Tab>
              );
            })}
          </TabList>
          <ProjectConfig active={currentTab === tabList.project} auth={auth} />
          <Requirements active={currentTab === tabList.requirements} auth={auth} />
          <Board active={currentTab === tabList.board} auth={auth} />
          <Admin
            active={currentTab === tabList.admin}
            auth={auth}
            project={project}
            phases={phases}
          />
        </TabbedPanel>
      </Content>
    </ProjectContext.Provider>
  );
};

export default Project;
