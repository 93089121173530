import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyAEvgykf0GlpADSkBUB5K1mIX-_xbjDCt8',
  authDomain: 'acmosdomo.firebaseapp.com',
  projectId: 'acmosdomo',
  storageBucket: 'acmosdomo.appspot.com',
  messagingSenderId: '876273288561',
  appId: '1:876273288561:web:b7803eea8c318fc7ea62d0',
  measurementId: 'G-5YLSHF73ZC',
};

const app = initializeApp(firebaseConfig, 'domo');

const instance = {
  app,
  db: getFirestore(app),
  functions: getFunctions(app),
};

export default instance;
