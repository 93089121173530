import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyDYlcFp-VP52qDayRvNjWtSv5iH8EEaaLU',
  authDomain: 'acmos-formwork.firebaseapp.com',
  projectId: 'acmos-formwork',
  storageBucket: 'acmos-formwork.appspot.com',
  messagingSenderId: '852272030337',
  appId: '1:852272030337:web:1cccedab09deb2028a2380',
  measurementId: 'G-S4BHNW90J9',
};

const app = initializeApp(firebaseConfig, 'formwork');

const instance = {
  app,
  db: getFirestore(app),
  functions: getFunctions(app),
};

export default instance;
