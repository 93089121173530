import { Icon, Text, TEXT_SIZES } from '@acmos/ui';
import { useContext, useEffect, useState } from 'react';
import { i18nContext } from '../../../../i18n';
import EditableEntityProp from '../EditableEntityProp';
import styles from './EditableFieldWithModal.module.scss';

export const extractTextFromHtml = (value) => {
  var div = document.createElement('div');
  div.innerHTML = value;
  return div.innerText;
};

const EditableFieldWithModal = ({
  auth,
  entity,
  attribute,
  entityChange,
  visible,
  title,
  subtitle,
  singleLine,
  onClose,
}) => {
  const { i18n } = useContext(i18nContext);
  const stringDescription = extractTextFromHtml(entity[attribute]);
  const close = (e) => {
    if (e.key === 'Escape') {
      onClose?.();
    }
    return true;
  };
  useEffect(() => {
    if (!visible) return;
    window.addEventListener('keydown', close);
    return () => {
      window.removeEventListener('keydown', close);
    };
  }, [visible]);
  return (
    <>
      <div className={styles.EllipsedText}>{!visible && stringDescription}</div>
      {visible && (
        <div
          className={[styles.DescriptionModal, styles.Visible].join(' ')}
          onClick={(e) => e.stopPropagation()}>
          <div className={styles.Content}>
            <div className={styles.ModalHeader}>
              <Text size={TEXT_SIZES.MEDIUM}>
                <span dangerouslySetInnerHTML={{ __html: title }}></span>
              </Text>
              <Text size={TEXT_SIZES.NORMAL}>{subtitle}</Text>
              <div className={styles.Close} onClick={onClose}>
                <Icon icon="xmark" />
              </div>
            </div>
            <div className={styles.DescriptionModalContent}>
              <Text extraClass={styles.DescriptionHeader}>
                {i18n('project.modal.descriptionHeader')}
              </Text>
              <EditableEntityProp
                auth={auth}
                onFocus={() => {}}
                onBlur={() => {}}
                value={entity.description}
                onChange={entityChange}
                singleLine={singleLine}
                touching={entity.touching?.[attribute]}
                extraClass={styles.DescriptionModalContentEditable}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditableFieldWithModal;
