import React from 'react'
import styles from './Space.scss'
export const ExtraTightSpace = () => {
  return <div className={[styles.Container, styles.ExtraTight].join(' ')}></div>
}
export const TightSpace = () => {
  return <div className={[styles.Container, styles.Tight].join(' ')}></div>
}
export const BaseSpace = () => {
  return <div className={[styles.Container, styles.Base].join(' ')}></div>
}
export const LooseSpace = () => {
  return <div className={[styles.Container, styles.Loose].join(' ')}></div>
}
export const ExtraLooseSpace = () => {
  return <div className={[styles.Container, styles.ExtraLoose].join(' ')}></div>
}

export const FlexSpace = () => {
  return <div className={[styles.Container, styles.Flex].join(' ')}></div>
}
