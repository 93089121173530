import { domoInstance, Button, BUTTON_COLORS, BUTTON_SIZES, Input } from '@acmos/ui';

import { useContext, useRef, useState } from 'react';
import { i18nContext } from '../../../i18n';
import EditableEntityProp from '../components/EditableEntityProp';
import EditableFieldWithModal from '../components/EditableFieldWithModal/EditableFieldWithModal';
import Importance from '../components/Importance';
import { ProjectContext } from '../Project';

import styles from './ActivitiesList.module.scss';

const Activity = ({ auth, activity, requirement }) => {
  const [descriptionVisible, setDescriptionVisible] = useState(false);
  const saveActivityDebounce = useRef();

  const { sprints, project } = useContext(ProjectContext);
  const { i18n } = useContext(i18nContext);

  const activityChange = (prop) => (e) => {
    activity[prop] = typeof e === 'string' ? e : e.target.value || e.target.innerHTML;
    clearTimeout(saveActivityDebounce.current);
    saveActivityDebounce.current = setTimeout(() => {
      activity.save(domoInstance.db);
    }, 500);
  };

  const remove = (activity) => (e) => {
    activity.delete(domoInstance.db);
  };

  return (
    <tr className={styles.Activity} key={activity.id}>
      <td className={styles.NumberContainer}> #{activity.number}</td>
      <td className={[styles.CellForEditing, styles.Title].join(' ')}>
        <EditableEntityProp
          auth={auth}
          onFocus={() => {}}
          onBlur={() => {}}
          value={activity.title}
          singleLine={true}
          onChange={activityChange('title')}
          touching={activity.touching?.['title']}
        />
      </td>
      <td
        className={[styles.CellForEditing, styles.Description].join(' ')}
        onClick={() => setDescriptionVisible(true)}>
        <EditableFieldWithModal
          visible={descriptionVisible}
          auth={auth}
          entity={activity}
          attribute={'description'}
          entityChange={activityChange('description')}
          onClose={() => setDescriptionVisible(false)}
          title={`#${activity.number} - ${activity.title || 'Sem título ainda'}`}
          description="Descrição da Atividade"
        />
      </td>

      <td className={[styles.Sprint, styles.CellForEditing].join(' ')}>
        <Input
          value={activity.sprintId}
          onChange={activityChange('sprintId')}
          selectedItemProp="id"
          items={sprints}
          itemDisplayProps={['title']}
          containerExtraClass={styles.Input}
        />
      </td>
      <td className={[styles.Status, styles.CellForEditing].join(' ')}>
        <Input
          value={activity.status}
          onChange={activityChange('status')}
          selectedItemProp="id"
          items={project.columns}
          itemDisplayProps={(item) => {
            return item?.title ? item?.title : i18n(item?.status);
          }}
          containerExtraClass={styles.Input}
        />
      </td>

      <td className={[styles.Cell, styles.Importance].join(' ')}>
        <Importance entity={activity} />
      </td>
      <td className={[styles.Cell, styles.ActionsCell].join(' ')}>
        <div className={styles.Actions}>
          <Button
            round
            leftIcon="trash"
            onClick={remove(activity)}
            color={BUTTON_COLORS.RED}></Button>
        </div>
      </td>
    </tr>
  );
};
export default Activity;
