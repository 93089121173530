import React, { useState } from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Content from '../../components/Page/Content/Content';
import Text, { TEXT_SIZES } from '../../components/Text/Text';
import styles from './Auth.scss';
import { APPS } from '../../auth/service';
import { ReactComponent as DomoLogo } from '../../assets/images/domo/logo-01-with-text.svg';
import { ReactComponent as CloudLogo } from '../../assets/images/cloud/logo-solo_1.svg';
import { FlexSpace } from '../../components/Space/Space';
import Loading from '../../components/Loading/Loading';

const APP_IMAGES = {
  [APPS.DOMO]: DomoLogo,
};
console.log('HERE', DomoLogo);
const AUTH_ERRORS = {
  'auth/invalid-email': 'Email Inválido',
  'auth/user-not-found': 'Usuário não encontrado',
  'auth/wrong-password': 'Senha Incorreta',
};

const Auth = ({ auth, appName }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const authenticate = () => {
    setLoading(true);

    auth
      .login(email, password)
      .catch((e) => {
        setError(AUTH_ERRORS[e.code] || e.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const headers = {
    [APPS.DOMO]: () => (
      <div className={styles.TopBar}>
        <div className={styles.TopBarContent}>
          <Button extraClass={styles.TopButton}>CONHEÇA O DOMO</Button>
          <Button extraClass={styles.TopButton}>NOS CONTATE</Button>
          <Button extraClass={styles.TopButton}>AJUDA</Button>
          <FlexSpace />
          <Text size={TEXT_SIZES.NORMAL}>Entre no DOMO</Text>
          <Button
            extraClass={[styles.TopButton, styles.TopButtonNewAccount].join(
              ' ',
            )}
          >
            QUERO UMA CONTA
          </Button>
        </div>
      </div>
    ),
    [APPS.CLOUD]: () => (
      <div className={styles.TopBar}>
        <div className={styles.TopBarContent}>
          <Button extraClass={styles.TopButton}>ACMOS CLOUD</Button>
          <Button extraClass={styles.TopButton}>NOS CONTATE</Button>
          <Button extraClass={styles.TopButton}>AJUDA</Button>
          <FlexSpace />
          <Text size={TEXT_SIZES.NORMAL}>Entre na Cloud da ACMOS</Text>
          <Button
            extraClass={[styles.TopButton, styles.TopButtonNewAccount].join(
              ' ',
            )}
          >
            QUERO UMA CONTA
          </Button>
        </div>
      </div>
    ),
  };
  const welcome = {
    [APPS.DOMO]: () => 'Bem vindo ao DOMO',
    [APPS.CLOUD]: () => 'Bem vindo a Cloud da ACMOS',
  };
  const logo = {
    [APPS.DOMO]: () => <DomoLogo />,
    [APPS.CLOUD]: () => <CloudLogo />,
  };

  return (
    <div className={styles.Main}>
      {headers[appName]()}
      <div className={styles.Content}>
        <div className={styles.MarketingBox}>{logo[appName]()}</div>
        <div className={styles.LoginBox}>
          {loading && <Loading />}
          <Text extraClass={styles.Title} size={TEXT_SIZES.MEDIUM}>
            {welcome[APPS.CLOUD]}
          </Text>
          <Input
            label={'Seu Email'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name='email'
            leftIcon={'envelope'}
            onKeyPress={(e) => {
              if (e.key === 'Enter') authenticate({});
            }}
          />
          <Input
            label={'Sua Senha'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name='password'
            type={passwordVisible ? '' : 'password'}
            leftIcon={'key'}
            rightIcon={passwordVisible ? 'eye-slash' : 'eye'}
            onRightIconClick={() => setPasswordVisible(!passwordVisible)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') authenticate({});
            }}
          />
          {!!error && <div className={styles.Error}>{error}</div>}
          <Button onClick={authenticate}>ENTRAR</Button>
        </div>
      </div>
    </div>
  );
};

export default Auth;
