import React from 'react';
import PropTypes from 'prop-types';

import styles from './Text.scss';

export const TEXT_SIZES = {
  SMALL: 'Small',
  NORMAL: 'Normal',
  MEDIUM: 'Medium',
  LARGE: 'Large',
};
const Text = ({ children, size = TEXT_SIZES.NORMAL, extraClass }) => {
  return (
    <div
      className={[
        styles.Container,
        styles['Size' + size] || '',
        extraClass || '',
      ].join(' ')}
    >
      {children}
    </div>
  );
};

Text.propTypes = {};

export default Text;

// import PropTypes from "prop-types";

// import styles from "./Typography.module.scss";

// export const TEXT_SIZES = {
//   XXXL: styles.XXXLText,
//   XXL: styles.XXLText,
//   XL: styles.XLText,
//   L: styles.LText,
//   M: styles.MText,
//   S: styles.SText,
//   XS: styles.XSText,
//   XXS: styles.XXSText,
// };
// export const TEXT_DECORATIONS = {
//   BOLD: styles.Bold,
//   UNDERLINE: styles.Underline,
//   UPPERCASE: styles.UpperCase,
//   LOWERCASE: styles.LowerCase,
//   ITALIC: styles.Italic,
//   LINETRHOUGH: styles.LineTrhough,
// };

// const Text = ({ size, serif, decorations, extraClass }) => {
//   return (
//     <span
//       className={[
//         styles.SomeBasicStyle,
//         size || "",
//         serif ? styles.SerifFont : styles.SansSerifFont,
//         decorations instanceof Array ? Object.values(decorations) : "",
//         extraClass || "",
//       ].join(" ")}
//     >
//       {children}
//     </span>
//   );
// };

// Text.propTypes = {
//   size: PropTypes.oneOf(Object.values(TEXT_SIZES)),
//   serif: PropTypes.bool,
//   decorations: PropTypes.arrayOf(
//     PropTypes.oneOf(Object.values(TEXT_DECORATIONS)),
//   ),
// };

// export default Text;
