import React from 'react'
import PropTypes from 'prop-types'

import styles from './Content.scss'

const Content = ({ children, extraClass, ...rest }) => {
  return (
    <div className={[styles.Container, extraClass || ''].join(' ')} {...rest}>
      {children}
    </div>
  )
}

Content.propTypes = {
  children: PropTypes.node
}

export default Content
