import { domoInstance, Text } from '@acmos/ui';
import { useContext, useEffect, useMemo } from 'react';
import { i18nContext } from '../../../i18n';
import { ProjectContext } from '../Project';
import Phase from './Phase';
import styles from './Phases.module.scss';

let saveProjectDebounce;
const Phases = ({ auth }) => {
  const { i18n } = useContext(i18nContext);
  const { phases, project } = useContext(ProjectContext);

  const projectChange = (prop) => (e) => {
    project[prop] = e.target.value || e.target.innerHTML;
    clearTimeout(saveProjectDebounce);
    saveProjectDebounce = setTimeout(() => {
      project.save(domoInstance.db);
    }, 500);
  };

  return (
    <div className={styles.Phases}>
      <Text extraClass={styles.Header}>{i18n('project.phase.header.projectName')}</Text>
      <div
        className={styles.ProjectTitle}
        contentEditable={true}
        spellCheck="false"
        suppressContentEditableWarning={true}
        dangerouslySetInnerHTML={{ __html: project.title }}
        onInput={projectChange('title')}
      />
      <br />
      <Text extraClass={styles.Header}>{i18n('project.phase.header.title')}</Text>
      <table className={styles.Table}>
        <thead>
          <tr className={styles.ContentTR}>
            <th></th>
            <th>{i18n('project.phase.fields.title')}</th>
            <th>{i18n('project.phase.fields.dateStart')}</th>
            <th>{i18n('project.phase.fields.dateEnd')}</th>
            <th className={styles.Actions}></th>
          </tr>
        </thead>
        <tbody>
          {phases.map((phase) => {
            return <Phase key={phase.id} auth={auth} phase={phase} project={project} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Phases;
