import { domoInstance, BaseSpace, Button, Content, Text, TEXT_SIZES, authService } from '@acmos/ui';
import { DomoModels } from '@acmos/models';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Home.module.scss';

const Home = () => {
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const authStore = authService.useAuthStore((s) => s);
  useEffect(() => {
    setList([]);
    if (!authStore.currentCompany.id) return;
    return DomoModels.Project.subscribeAll(
      domoInstance.db,
      [['company', '==', authStore.currentCompany.id]],
      setList
    );
  }, [authStore.currentCompany?.id]);

  const addProject = () => {
    const project = new DomoModels.Project();
    project.company = authStore.currentCompany.id;
    project.title = 'Projeto sem nome';
    project.columns = [
      { title: '', status: 'project.config.board.defaultColumns.todo' },
      { title: '', status: 'project.config.board.defaultColumns.inprogress' },
      { title: '', status: 'project.config.board.defaultColumns.inreview' },
      { title: '', status: 'project.config.board.defaultColumns.qualityTest' },
      { title: '', status: 'project.config.board.defaultColumns.notCompliant' },
      { title: '', status: 'project.config.board.defaultColumns.done' },
    ];
    project.save(domoInstance.db);
  };

  return (
    <Content>
      <div className={styles.Header}>
        <Text extraClass={styles.Title} size={TEXT_SIZES.MEDIUM}>
          Projetos
        </Text>
        <BaseSpace />
        <Button onClick={addProject}>ADICIONAR</Button>
      </div>
      <div className={styles.Projects}>
        {list.map((item) => {
          return (
            <div
              key={item.id}
              className={styles.Project}
              onClick={() => {
                navigate('/project/project/' + item.id);
              }}>
              {item.title}
            </div>
          );
        })}
      </div>
    </Content>
  );
};

export default Home;
