import {
  Button,
  BUTTON_COLORS,
  BUTTON_SIZES,
  Icon,
  Input,
  Text,
  TightSpace,
  uid,
  useDragAndDrop,
  domoInstance,
} from '@acmos/ui';
import { DomoModels } from '@acmos/models';
import { useContext, useEffect, useRef, useState } from 'react';
import { i18nContext } from '../../../i18n';
import { ProjectContext } from '../Project';
import styles from './ProjectConfig.module.scss';

const BoardConfig = () => {
  const [columns, setColumns] = useState([]);
  const { i18n } = useContext(i18nContext);
  const { project } = useContext(ProjectContext);
  const projectSaveDebounce = useRef();
  const { createDraggable } = useDragAndDrop();
  const droppableRef = useRef();
  const projectSaveRef = useRef();

  useEffect(() => {
    setColumns([...(project.columns || []).sortByKey((o) => [o.order])]);
  }, [project.columns]);

  const addColumn = () => {
    if (!(project.columns instanceof Array)) {
      return (project.columns = []);
    }
    columns.push({
      order: project.columns.length,
      id: uid(),
      title: '',
      status: '',
    });
    setColumns([...columns]);
    project.columns = columns;

    clearTimeout(projectSaveDebounce.current);
    projectSaveRef.current = setTimeout(() => {
      project.save(domoInstance.db);
    }, 300);
  };

  const onColumnChange = (column, prop) => (e) => {
    column[prop] = typeof e === 'string' ? e : e.target.value;

    clearTimeout(projectSaveDebounce.current);
    projectSaveDebounce.current = setTimeout(() => {
      project.save(domoInstance.db);
    }, 1000);
  };

  const removeColumn = (column, i) => (e) => {
    columns.splice(i, 1);
    project.columns = columns;
    project.save(domoInstance.db);
  };

  const onDragEnd =
    (column) =>
    ({ newIndex }) => {
      let currentIndex = project.columns.findIndex((o) => o === column);
      if (currentIndex === -1) return;
      columns.move(currentIndex, newIndex);
      columns.map((c, i) => (c.order = i));
      console.log('COLUMNS', columns);
      setColumns([...columns].sortByKey((o) => [o.order]));
      project.columns = columns;
      console.log('NEW INDEX', columns, currentIndex, newIndex);
      project.save(domoInstance.db);
    };

  return (
    <div className={styles.BoardConfig}>
      <Text extraClass={styles.Header}>{i18n('project.config.board.title')}</Text>
      <TightSpace />
      <Text extraClass={styles.Header}>
        {i18n('project.config.board.columnsHeader')}
        <TightSpace />
        <Button leftIcon="plus" onClick={addColumn}>
          {i18n('project.config.board.addColumn')}
        </Button>
      </Text>
      <TightSpace />
      <div className={styles.Columns} ref={droppableRef}>
        {columns?.map((column, i) => {
          return (
            <div
              className={styles.Column}
              key={column.id}
              {...createDraggable({
                sortingOptions: {
                  droppableRefs: [droppableRef],
                },
                onDragEnd: onDragEnd(column),
                gripSelector: styles.DragGrip,
              })}>
              <Input
                value={column.title}
                onChange={onColumnChange(column, 'title')}
                containerExtraClass={styles.ColumnInput}
                placeholder={i18n('project.config.board.column.title')}
              />
              <Input
                value={column.status}
                onChange={onColumnChange(column, 'status')}
                containerExtraClass={[styles.ColumnInput, styles.ColumnSelected].join(' ')}
                placeholder={i18n('project.config.board.column.status')}
                items={Object.values(DomoModels.Activity.DEFAULT_ACTIVITY_STATUS)}
                itemDisplayProps={(value) => {
                  return i18n(value);
                }}
              />
              <Button
                extraClass={styles.ColumnRemove}
                onClick={removeColumn(column, i)}
                color={BUTTON_COLORS.RED}>
                <Icon icon="trash" />
              </Button>
              <div className={styles.DragGrip}>
                <Icon icon="grip-vertical" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BoardConfig;
