import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  getIdToken,
} from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { getDoc, doc } from 'firebase/firestore';

import create from 'zustand';
import { persist } from 'zustand/middleware';

import cloudInstance from '../applications/cloud-firebase-client';
import domoInstance from '../applications/domo-firebase-client';
import formworkInstane from '../applications/formwork-firebase-client';

const authenticate = httpsCallable(cloudInstance.functions, 'authenticate');

export const APPS = {
  DOMO: 'domo',
  CLOUD: 'cloud',
  FORMWORK: 'formwork',
};

export const createCurrentUser = (user) => {
  return {
    uid: user.uid,
    email: user.email || '',
    displayName: user.displayName,
    emailVerified: user.emailVerified || '',
    phoneNumber: user.phoneNumber || '',
    photoURL: user.photoURL || '',
  };
};
export const createCurrentCompany = () => {
  return {
    id: '',
    name: '',
  };
};

const auth = getAuth(cloudInstance.app);
const domoAuth = getAuth(domoInstance.app);
const formworkAuth = getAuth(formworkInstane.app);

const authStore = create(
  persist(
    (set, get) => ({
      currentUser: createCurrentUser({}),
      currentCompany: createCurrentCompany(),
      setCurrentUser: (current) => {
        set((state) => (state.currentUser = current));
      },
      setCurrentCompany: (current) => {
        set((state) => (state.currentCompany = current));
      },
    }),
    {
      name: 'asmapps',
    },
  ),
);

const login = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password).then(
    (credentials) => {
      return getIdToken(credentials.user, true).then((tokenResult) => {
        return authenticate(tokenResult).then((result) => {
          authStore.setState({
            currentUser: result.data.user,
            currentCompany: {
              id: Object.keys(result.data.user.companies)[0],
            },
          });
          return Promise.all([
            signInWithCustomToken(domoAuth, result.data.tokens.domo),
            signInWithCustomToken(formworkAuth, result.data.tokens.formwork),
          ]);
        });
      });
    },
  );
};
const logout = () => {
  return Promise.all([
    domoAuth.signOut(),
    formworkAuth.signOut(),
    auth.signOut().then((r) => {
      authStore.setState({
        currentUser: createCurrentUser({}),
      });
    }),
  ]);
};

const checkAuthState = () => {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const currentUser = authStore.getState().currentUser;
      if (currentUser.uid == user.uid) return;

      return;
    }
  });
};

export default {
  useAuthStore: authStore,
  login,
  logout,
  checkAuthState,
};
