import React, { useState, Fragment } from 'react';
import Icon from '../Icon/Icon';
import styles from './Toast.scss';

const Toast = ({ children, ...rest }) => {
  const [visible, setVisible] = useState(true);
  return (
    <>
      {visible && (
        <div className={styles.Toast} {...rest}>
          <div className={styles.Content}>{children}</div>
          <div className={styles.Close} onClick={() => setVisible(false)}>
            <Icon icon='xmark' />
          </div>
        </div>
      )}
    </>
  );
};

export default Toast;
