import { domoInstance, Button, BUTTON_SIZES, Icon } from '@acmos/ui';
import { DomoModels } from '@acmos/models';
import { useContext, useEffect, useState } from 'react';
import { i18nContext } from '../../../../i18n';
import EditableEntityProp from '../EditableEntityProp';
import styles from './Discussion.module.scss';

const Discussion = ({ auth, entity }) => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  const { format } = useContext(i18nContext);
  const authStore = auth.useAuthStore((s) => s);

  useEffect(() => {
    if (!entity.id) return;
    return DomoModels.Discussion.subscribeAll(
      domoInstance.db,
      [['entityId', '==', entity.id]],
      (list) => setMessages(list.sortByKey((o) => [o.createdAt]))
    );
  }, [entity.id]);

  const send = () => {
    let discussion = new DomoModels.Discussion();
    discussion.company = entity.company;
    discussion.entityId = entity.id;
    discussion.text = message;
    discussion.user = {
      uid: authStore.currentUser.uid,
      displayName: authStore.currentUser.displayName,
      photoUrl: authStore.currentUser.photoUrl || '',
      email: authStore.email || '',
    };
    discussion.save(domoInstance.db);
    setMessage('');
  };

  const remove = (message) => () => {
    message.delete(domoInstance.db);
  };

  return (
    <div className={styles.Container}>
      <div className={styles.Messages}>
        {messages.map((message) => {
          return (
            <div className={styles.Message}>
              <div className={styles.User}>
                <div
                  className={styles.Avatar}
                  style={{
                    background: message.user.photoUrl,
                  }}>
                  {message.user.displayName
                    ?.split(' ')
                    .map((a) => a[0])
                    .join('')}
                </div>
                <div className={styles.Info}>
                  <div className={styles.Name}>{message.user.displayName}</div>
                  <div className={styles.Date}>{format.datetime(new Date(message.createdAt))}</div>
                </div>
                <div>
                  {authStore.currentUser.uid === message.user.uid && (
                    <Button
                      extraClass={styles.Remove}
                      onClick={remove(message)}
                      leftIcon="trash"></Button>
                  )}
                </div>
              </div>

              <div
                className={styles.Content}
                dangerouslySetInnerHTML={{ __html: message.text }}></div>
            </div>
          );
        })}
      </div>
      <div className={styles.Editable}>
        <EditableEntityProp value={message} onChange={setMessage} extraClass={styles.TextField} />
        <Button onClick={send} extraClass={styles.Button}>
          <Icon icon="paper-plane" />
        </Button>
      </div>
    </div>
  );
};

export default Discussion;
