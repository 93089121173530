import React from 'react';
import Icon from '../Icon/Icon';
import { ExtraTightSpace, TightSpace } from '../Space/Space';
import styles from './Button.scss';

export const BUTTON_SIZES = {
  SMALL: 'Small',
  NORMAL: 'Normal',
  BIG: 'Big',
};

export const BUTTON_COLORS = {
  GREEN: 'Green',
  YELLOW: 'Yellow',
  BLUE: 'Blue',
  ORANGE: 'Orange',
  RED: 'Red',
  WHITE: 'White',
};

const Button = ({
  children,
  round,
  size,
  leftIcon,
  rightIcon,
  color,
  extraClass,
  ...rest
}) => {
  return (
    <button
      className={[
        styles.Button,
        round ? styles.Round : '',
        size ? styles['Size' + size] : '',
        color ? styles['Color' + color] : '',
        extraClass || '',
      ].join(' ')}
      {...rest}
    >
      <div className={styles.Darken}></div>
      <span className={styles.Content}>
        {!!leftIcon && [
          <Icon icon={leftIcon} />,
          children && children.length ? <ExtraTightSpace /> : '',
        ]}
        {children}
        {!!rightIcon && [<Icon icon={rightIcon} />, <ExtraTightSpace />]}
      </span>
    </button>
  );
};

export default Button;
