import React from 'react';

const Icon = ({ icon, extraClass, ...rest }) => {
  return (
    <i
      className={[`fa-solid fa-${icon}`, extraClass || ''].join(' ')}
      {...rest}
    ></i>
  );
};

export default Icon;
