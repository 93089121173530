function cmp(a, b) {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  } else {
    return 0;
  }
}

Array.prototype.sortByKey = function sortByKey(key) {
  return this.sort((a, b) => cmp(key(a), key(b)));
};
Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};
