export const numberCustomMask = (mask, number) => {
  var s = '' + number,
    r = '';
  for (var im = 0, is = 0; im < mask.length && is < s.length; im++) {
    r += mask.charAt(im) == 'X' ? s.charAt(is++) : mask.charAt(im);
  }
  return r;
};

// prettier-ignore
export const uid = () => Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('');
