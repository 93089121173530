"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  abbreviation: "AMP",
  description: "AMPOLA",
  abbreviation_nfe: "AMPOLA"
}, {
  abbreviation: "BALDE",
  description: "BALDE",
  abbreviation_nfe: "BALDE"
}, {
  abbreviation: "BAND",
  description: "BANDEJA",
  abbreviation_nfe: "BANDEJ"
}, {
  abbreviation: "BARR",
  description: "BARRA",
  abbreviation_nfe: "BARRA"
}, {
  abbreviation: "BISN",
  description: "BISNAGA",
  abbreviation_nfe: "BISNAG"
}, {
  abbreviation: "BLOCO",
  description: "BLOCO",
  abbreviation_nfe: "BLOCO"
}, {
  abbreviation: "BOBIN",
  description: "BOBINA",
  abbreviation_nfe: "BOBINA"
}, {
  abbreviation: "BOMB",
  description: "BOMBONA",
  abbreviation_nfe: "BOMB"
}, {
  abbreviation: "CAPS",
  description: "CAPSULA",
  abbreviation_nfe: "CAPS"
}, {
  abbreviation: "CART",
  description: "CARTELA",
  abbreviation_nfe: "CART"
}, {
  abbreviation: "CENTO",
  description: "CENTO",
  abbreviation_nfe: "CENTO"
}, {
  abbreviation: "CJ",
  description: "CONJUNTO",
  abbreviation_nfe: "CJ"
}, {
  abbreviation: "CM",
  description: "CENTIMETRO",
  abbreviation_nfe: "CM"
}, {
  abbreviation: "CM2",
  description: "CENTIMETRO QUADRADO",
  abbreviation_nfe: "CM2"
}, {
  abbreviation: "CX",
  description: "CAIXA",
  abbreviation_nfe: "CX"
}, {
  abbreviation: "CX10",
  description: "CAIXA COM 10 UNIDADES",
  abbreviation_nfe: "CX10"
}, {
  abbreviation: "CX100",
  description: "CAIXA COM 100 UNIDADES",
  abbreviation_nfe: "CX100"
}, {
  abbreviation: "CX15",
  description: "CAIXA COM 15 UNIDADES",
  abbreviation_nfe: "CX15"
}, {
  abbreviation: "CX2",
  description: "CAIXA COM 2 UNIDADES",
  abbreviation_nfe: "CX2"
}, {
  abbreviation: "CX20",
  description: "CAIXA COM 20 UNIDADES",
  abbreviation_nfe: "CX20"
}, {
  abbreviation: "CX25",
  description: "CAIXA COM 25 UNIDADES",
  abbreviation_nfe: "CX25"
}, {
  abbreviation: "CX3",
  description: "CAIXA COM 3 UNIDADES",
  abbreviation_nfe: "CX3"
}, {
  abbreviation: "CX5",
  description: "CAIXA COM 5 UNIDADES",
  abbreviation_nfe: "CX5"
}, {
  abbreviation: "CX50",
  description: "CAIXA COM 50 UNIDADES",
  abbreviation_nfe: "CX50"
}, {
  abbreviation: "DISP",
  description: "DISPLAY",
  abbreviation_nfe: "DISP"
}, {
  abbreviation: "DUZIA",
  description: "DUZIA",
  abbreviation_nfe: "DUZIA"
}, {
  abbreviation: "EMB",
  description: "EMBALAGENS",
  abbreviation_nfe: "EMBAL"
}, {
  abbreviation: "FARDO",
  description: "FARDO",
  abbreviation_nfe: "FARDO"
}, {
  abbreviation: "FOLHA",
  description: "FOLHA",
  abbreviation_nfe: "FOLHA"
}, {
  abbreviation: "FRSC",
  description: "FRASCO",
  abbreviation_nfe: "FRASCO"
}, {
  abbreviation: "GALAO",
  description: "GALÃO",
  abbreviation_nfe: "GALAO"
}, {
  abbreviation: "GF",
  description: "GARRAFA",
  abbreviation_nfe: "GF"
}, {
  abbreviation: "GR",
  description: "GRAMAS",
  abbreviation_nfe: "GRAMAS"
}, {
  abbreviation: "H",
  description: "HORA",
  abbreviation_nfe: "HORA"
}, {
  abbreviation: "DIA",
  description: "DIA",
  abbreviation_nfe: "DIA"
}, {
  abbreviation: "SEMANA",
  description: "SEMANA",
  abbreviation_nfe: "SEMANA"
}, {
  abbreviation: "MÊS",
  description: "MÊS",
  abbreviation_nfe: "MÊS"
}, {
  abbreviation: "JOGO",
  description: "JOGO",
  abbreviation_nfe: "JOGO"
}, {
  abbreviation: "K",
  description: "QUILATE",
  abbreviation_nfe: "K"
}, {
  abbreviation: "KG",
  description: "KILO GRAMA",
  abbreviation_nfe: "KG"
}, {
  abbreviation: "KIT",
  description: "KIT",
  abbreviation_nfe: "KI"
}, {
  abbreviation: "LATA",
  description: "LATA",
  abbreviation_nfe: "LATA"
}, {
  abbreviation: "LT",
  description: "LITRO",
  abbreviation_nfe: "LITRO"
}, {
  abbreviation: "M",
  description: "METRO",
  abbreviation_nfe: "M"
}, {
  abbreviation: "M2",
  description: "METRO QUADRADO",
  abbreviation_nfe: "M2"
}, {
  abbreviation: "M3",
  description: "METRO CÚBICO",
  abbreviation_nfe: "M3"
}, {
  abbreviation: "MILH",
  description: "MILHEIRO",
  abbreviation_nfe: "MILHEI"
}, {
  abbreviation: "ML",
  description: "MILILITROS",
  abbreviation_nfe: "ML"
}, {
  abbreviation: "MWG",
  description: "MEGAWATT HORA",
  abbreviation_nfe: "MWH"
}, {
  abbreviation: "PALET",
  description: "PALETE",
  abbreviation_nfe: "PALETE"
}, {
  abbreviation: "PC",
  description: "PECAS",
  abbreviation_nfe: "PC"
}, {
  abbreviation: "PCT",
  description: "PACOTE",
  abbreviation_nfe: "PACOTE"
}, {
  abbreviation: "POTE",
  description: "POTE",
  abbreviation_nfe: "POTE"
}, {
  abbreviation: "PR",
  description: "PARES",
  abbreviation_nfe: "PARES"
}, {
  abbreviation: "RESMA",
  description: "RESMA",
  abbreviation_nfe: "RESMA"
}, {
  abbreviation: "ROLO",
  description: "ROLO",
  abbreviation_nfe: "ROLO"
}, {
  abbreviation: "SACLA",
  description: "SACOLA",
  abbreviation_nfe: "SACOLA"
}, {
  abbreviation: "SACO",
  description: "SACO",
  abbreviation_nfe: "SACO"
}, {
  abbreviation: "TAMB",
  description: "TAMBOR",
  abbreviation_nfe: "TAMBOR"
}, {
  abbreviation: "TANQ",
  description: "TANQUE",
  abbreviation_nfe: "TANQUE"
}, {
  abbreviation: "TON",
  description: "TONELADA",
  abbreviation_nfe: "TON"
}, {
  abbreviation: "TUBO",
  description: "TUBO",
  abbreviation_nfe: "TUBO"
}, {
  abbreviation: "UN",
  description: "UNIDADE",
  abbreviation_nfe: "UNID"
}, {
  abbreviation: "VASIL",
  description: "VASILHAME",
  abbreviation_nfe: "VASIL"
}, {
  abbreviation: "VIDRO",
  description: "VIDRO",
  abbreviation_nfe: "VIDRO"
}];
exports["default"] = _default;