import React, { useState, useEffect } from 'react';
import Icon from '../Icon/Icon';
import styles from './Input.scss';

const Checkbox = ({ value, onChange }) => {
  const [_state, _setState] = useState({ value: null });
  useEffect(() => {
    if (_state.value !== value) _setState({ value });
  }, [value]);

  useEffect(() => {
    if (_state.value !== null && value !== _state.value) {
      onChange?.({ target: { value: _state.value } });
    }
  }, [_state.value]);

  return (
    <div
      className={styles.Checkbox}
      onClick={() => {
        _state.value = !_state.value;
        _setState({ value: _state.value });
      }}
    >
      <div className={styles.Box}>
        <Icon icon={_state.value ? 'check' : ''} />
      </div>
    </div>
  );
};

export default Checkbox;
