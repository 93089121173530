import React from 'react';
import styles from './TabbedPanel.scss';
const TabbedPanel = ({ extraClass, children }) => {
  return (
    <div className={[styles.TabbedPanel, extraClass || ''].join(' ')}>
      {children}
    </div>
  );
};

export default TabbedPanel;
