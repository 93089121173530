import {
  Button,
  INPUT_SIZES,
  BUTTON_SIZES,
  FlexSpace,
  Input,
  TabContent,
  TightSpace,
  domoInstance,
} from '@acmos/ui';
import { DomoModels } from '@acmos/models';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import ContentHeader from '../components/ContentHeader';
import styles from './Requirement.module.scss';
import defaultStyles from '../Project.module.scss';
import RequirementRow from './RequirementRow';
import { i18nContext } from '../../../i18n';
import { ProjectContext } from '../Project';

const Requirements = ({ auth, active }) => {
  const [_searchText, _setSearchText] = useState('');
  const [searchText, setSearchText] = useState('');
  const searchTimeout = useRef();
  const authState = auth.useAuthStore((s) => s);
  const tableRef = useRef();
  const headerRowRef = useRef();
  const scrollTimeoutRef = useRef();
  const [columnsState, setColumnsState] = useState({});

  const { i18n } = useContext(i18nContext);
  const { project, requirements } = useContext(ProjectContext);

  useEffect(() => {
    clearTimeout(scrollTimeoutRef.current);
    const event = () => {
      clearTimeout(scrollTimeoutRef.current);
      scrollTimeoutRef.current = setTimeout(() => {
        let rect = tableRef.current?.getBoundingClientRect();

        if (!rect?.height) return;
        if (rect.top <= 0) {
          headerRowRef.current.classList.add(styles.FixedHeader);
        } else {
          headerRowRef.current.classList.remove(styles.FixedHeader);
        }
      }, 50);
    };
    event();
    window.addEventListener('scroll', event);
    return () => window.removeEventListener('scroll', event);
  }, [headerRowRef, tableRef]);

  const toggleColumn = (col) => () => {
    if (!columnsState[col]) {
      columnsState[col] = { closed: false };
    }
    columnsState[col].closed = !columnsState[col].closed;
    setColumnsState({ ...columnsState });
  };

  const addRequirement = (after) => {
    const requirement = new DomoModels.Requirement();
    requirement.company = authState.currentCompany.id;
    requirement.projectId = project.id;
    requirement.importance = 3;
    requirement.status = 'project.requirement.status.draft';
    if (after === undefined) {
      requirement.order =
        requirements.sort((a, b) => {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        })?.[requirements.length - 1]?.order || 0;
    } else {
      const batch = DomoModels.Requirement.getBatch(domoInstance.db);

      requirement.order = after.order + 1;
      requirement.parentId = after.parentId;
      let afterIndex = requirements.findIndex((o) => o.order === after.order);
      requirements.slice(afterIndex + 1).forEach((item) => {
        item.order = item.order + 1;
        item.save(domoInstance.db);
      });
      requirement.save(domoInstance.db, authState.currentUser, batch);

      batch.commit();
      return;
    }
    requirement.save(domoInstance.db, authState.currentUser);
  };

  const moveRight = (requirement, index) => (e) => {
    let scopedList = requirements.filter((o) => o.parentId === requirement.parentId);
    let previous = scopedList[index - 1];
    requirement.parentId = previous.id;
    requirement.save(domoInstance.db);
  };
  const moveLeft = (requirement, index) => (e) => {
    let parent = requirements.find((o) => o.id === requirement.parentId);
    requirement.parentId = parent.parentId;
    requirement.save(domoInstance.db);
  };

  const numberWidth = useMemo(() => {
    let levels = 0;
    requirements.forEach((req) => {
      let length = req.number.toString().split('.').length;
      if (length > levels) levels = length;
    });
    return levels * 14 + 71;
  }, [requirements]);

  return (
    <TabContent active={active}>
      <ContentHeader>
        <Button
          leftIcon="plus"
          onClick={() => addRequirement()}
          extraClass={defaultStyles.BarButton}>
          {i18n('project.requirement.newRequirement')}
        </Button>
        <TightSpace />
        <Input
          value={_searchText}
          onChange={(e) => {
            _setSearchText(e.target.value);
            clearTimeout(searchTimeout.current);
            searchTimeout.current = setTimeout(() => {
              setSearchText(e.target.value);
            }, 500);
          }}
          rightIcon="magnifying-glass"
          containerExtraClass={defaultStyles.SearchInput}
          size={INPUT_SIZES.SMALL}
        />
        <TightSpace />
        <Button
          leftIcon="print"
          onClick={() => {
            let page = document.getElementsByTagName('html')[0].cloneNode(true);
            let body = page.querySelector('body');
            body.innerHTML = tableRef.current.innerHTML;
            const winUrl = URL.createObjectURL(new Blob([page.outerHTML], { type: 'text/html' }));

            const win = window.open(winUrl, 'win', `width=800,height=400,screenX=200,screenY=200`);
            win.print();
          }}
          extraClass={defaultStyles.BarButton}></Button>
        <FlexSpace />
      </ContentHeader>

      <div className={styles.TableScrollContainer} ref={tableRef}>
        <div className={styles.RequirementTable}>
          <div
            className={[styles.RequirementMain, styles.RequirementHeaderRow].join(' ')}
            ref={headerRowRef}>
            <div className={styles.Requirement}>
              <div className={styles.Toggler}></div>
              <div
                onClick={toggleColumn('project.requirement.number')}
                style={{ width: numberWidth, minWidth: numberWidth }}
                className={[
                  styles.NumberContainer,
                  columnsState['project.requirement.number']?.closed ? styles.ColumnClosed : '',
                ].join('  ')}>
                <span>{i18n('project.requirement.number')}</span>
              </div>
              <div
                onClick={toggleColumn('project.requirement.title')}
                className={[
                  styles.CellForEditing,
                  styles.Title,

                  columnsState['project.requirement.title']?.closed ? styles.ColumnClosed : '',
                ].join(' ')}>
                <span>{i18n('project.requirement.title')}</span>
              </div>
              <div
                onClick={toggleColumn('project.requirement.description')}
                className={[
                  styles.CellForEditing,
                  styles.Description,

                  columnsState['project.requirement.description']?.closed
                    ? styles.ColumnClosed
                    : '',
                ].join(' ')}>
                <span>{i18n('project.requirement.description')}</span>
              </div>
              <div
                onClick={toggleColumn('project.requirement.status')}
                className={[
                  styles.SelectCell,
                  styles.Small,
                  styles.Status,

                  columnsState['project.requirement.status']?.closed ? styles.ColumnClosed : '',
                ].join(' ')}>
                <span>{i18n('project.requirement.status')}</span>
              </div>
              <div
                onClick={toggleColumn('project.requirement.phase')}
                className={[
                  styles.SelectCell,
                  styles.Small,
                  styles.Phase,

                  columnsState['project.requirement.phase']?.closed ? styles.ColumnClosed : '',
                ].join(' ')}>
                <span>{i18n('project.requirement.phase')}</span>
              </div>
              <div
                onClick={toggleColumn('project.requirement.estimate')}
                className={[
                  styles.InputCell,
                  styles.Small,
                  styles.Estimate,

                  columnsState['project.requirement.estimate']?.closed ? styles.ColumnClosed : '',
                ].join(' ')}>
                <span>
                  {i18n('project.requirement.estimate')}
                  <br />(
                  {requirements.reduce((prev, current) => {
                    return parseInt(current.estimate || 0) + parseInt(prev || 0);
                  }, 0)}
                  )
                </span>
              </div>
              <div
                onClick={toggleColumn('project.requirement.importance')}
                className={[
                  styles.Cell,
                  styles.Importance,
                  columnsState['project.requirement.importance']?.closed ? styles.ColumnClosed : '',
                ].join(' ')}>
                <span>{i18n('project.requirement.importance')}</span>
              </div>
              <div className={[styles.Cell, styles.ActionsCell].join(' ')}></div>
            </div>
          </div>
          <div className={styles.RequirementList}>
            {[...requirements]
              .filter((item) => {
                if (searchText) {
                  return (
                    JSON.stringify(item.title + item.description)
                      .toLowerCase()
                      .indexOf(searchText.toLowerCase()) > -1
                  );
                }
                return !item.parentId;
              })

              .map((item, i) => {
                return (
                  <RequirementRow
                    key={item.id}
                    searchText={searchText}
                    auth={auth}
                    addRequirement={addRequirement}
                    requirement={item}
                    moveLeft={moveLeft}
                    moveRight={moveRight}
                    numberWidth={numberWidth}
                    columnsState={columnsState}
                    index={i}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </TabContent>
  );
};

export default Requirements;
