import {
  domoInstance,
  Button,
  BUTTON_COLORS,
  BUTTON_SIZES,
  Input,
  Text,
  TightSpace,
  Checkbox,
} from '@acmos/ui';
import { DomoModels } from '@acmos/models';
import { useContext, useEffect, useState } from 'react';
import { i18nContext } from '../../../i18n';
import styles from './Phases.module.scss';

const Sprints = ({ auth, phase }) => {
  const [sprints, setSprints] = useState([]);

  const { i18n } = useContext(i18nContext);
  const authState = auth.useAuthStore((s) => s);

  useEffect(() => {
    return DomoModels.Sprint.subscribeAll(
      domoInstance.db,
      [['phaseId', '==', phase.id]],
      (list) => {
        setSprints(list.sortByKey((item) => [item.dateStart, item.createdAt]));
      }
    );
  }, [phase.id]);

  const onSprintChange = (sprint, prop) => (e) => {
    sprint[prop] = typeof e === 'string' ? e : e.target?.value || e.target?.innerHTML || '';
    if (prop === 'dateStart' && !sprint.dateEnd) {
      let dateEnd = new Date(sprint.dateStart);
      dateEnd.setDate(dateEnd.getDate() + parseInt(phase.sprintSize || 7));
      sprint.dateEnd = dateEnd.toISOString().substring(0, 10);
    }
    sprint.save(domoInstance.db);
  };

  const addSprint = () => {
    const sprint = new DomoModels.Sprint();
    sprint.company = authState.currentCompany.id;
    sprint.phaseId = phase.id;
    sprint.projectId = phase.projectId;
    sprint.title = i18n('project.phase.sprints.sprintWord') + ' ' + (sprints.length + 1);
    let lastSprint = sprints[sprints.length - 1];
    if (lastSprint && lastSprint.dateEnd) {
      let dateEnd = new Date(lastSprint.dateEnd);
      sprint.dateStart = dateEnd.toISOString().substring(0, 10);
      dateEnd.setDate(dateEnd.getDate() + parseInt(phase.sprintSize || 7));
      sprint.dateEnd = dateEnd.toISOString().substring(0, 10);
    } else {
      let dateEnd = new Date();
      dateEnd.setDate(dateEnd.getDate() + parseInt(phase.sprintSize || 7));
      sprint.dateStart = new Date().toISOString().substring(0, 10);
      sprint.dateEnd = dateEnd.toISOString().substring(0, 10);
    }
    sprint.save(domoInstance.db);
  };

  const remove = (sprint) => (e) => {
    sprint.delete(domoInstance.db);
  };

  return (
    <div className={styles.Sprints}>
      <Text>
        {i18n('project.phase.sprints.headerTitle')}
        <TightSpace />
        <Button leftIcon="plus" onClick={addSprint}>
          {i18n('project.phase.sprints.addSprint')}
        </Button>
      </Text>
      <div className={styles.SprintList}>
        <TightSpace />
        <table className={styles.Table}>
          <thead>
            <tr className={styles.ContentTR}>
              <th>{i18n('project.phase.sprints.title')}</th>
              <th>{i18n('project.phase.sprints.dateStart')}</th>
              <th>{i18n('project.phase.sprints.dateEnd')}</th>
              <th>{i18n('project.phase.sprints.active')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sprints.map((sprint) => {
              return (
                <tr className={styles.ContentTR} key={sprint.id}>
                  <td>
                    <Input
                      value={sprint.title}
                      onChange={onSprintChange(sprint, 'title')}
                      containerExtraClass={styles.Input}
                    />
                  </td>
                  <td className={styles.MinimumWidth}>
                    <Input
                      value={sprint.dateStart}
                      type="date"
                      onChange={onSprintChange(sprint, 'dateStart')}
                      containerExtraClass={styles.InputDateSprint}
                    />
                  </td>
                  <td className={styles.MinimumWidth}>
                    <Input
                      value={sprint.dateEnd}
                      type="date"
                      onChange={onSprintChange(sprint, 'dateEnd')}
                      containerExtraClass={styles.InputDateSprint}
                    />
                  </td>
                  <td className={styles.MinimumWidth}>
                    <Checkbox value={sprint.active} onChange={onSprintChange(sprint, 'active')} />
                  </td>
                  <td className={styles.Actions}>
                    <Button
                      color={BUTTON_COLORS.RED}
                      leftIcon="trash"
                      onClick={remove(sprint)}
                      round
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Sprints;
