export const getCookieValue = (name) => {
  const cookieObj = new URLSearchParams(document.cookie.replaceAll('; ', '&'));
  return cookieObj.get(name) || '';
};
export const setCookieValue = (name, value, expires) => {
  let rememberUntil = new Date();
  rememberUntil.setFullYear(rememberUntil.getFullYear() + 10);

  let domain = '';
  if (window.location.hostname.indexOf('.') > -1) {
    domain = `domain=.${window.location.hostname};`;
  }

  document.cookie = `${name}=${value}; expires=${
    expires || rememberUntil.toUTCString()
  }; ${domain}  path=/; secure`;
};
