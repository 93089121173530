import { domoInstance, Button, BUTTON_SIZES, TabContent } from '@acmos/ui';
import { DomoModels } from '@acmos/models';

import ContentHeader from '../components/ContentHeader';
import styles from './ProjectConfig.module.scss';
import defaultStyles from '../Project.module.scss';
import { useContext } from 'react';
import { i18nContext } from '../../../i18n';
import Phases from '../Phase/Phases';
import { ProjectContext } from '../Project';
import BoardConfig from './BoardConfig';

const ProjectConfig = ({ auth, active }) => {
  const { i18n } = useContext(i18nContext);
  const { project } = useContext(ProjectContext);
  const authState = auth.useAuthStore((s) => s);

  const addPhase = () => {
    const phase = new DomoModels.Phase();
    phase.company = authState.currentCompany.id;
    phase.projectId = project.id;
    phase.save(domoInstance.db);
  };

  return (
    <TabContent active={active}>
      <ContentHeader>
        <Button onClick={addPhase} extraClass={defaultStyles.BarButton} leftIcon="plus">
          {i18n('project.config.addNewPhase')}
        </Button>
      </ContentHeader>
      <div className={styles.Content}>
        <Phases auth={auth} />
        <BoardConfig auth={auth} />
      </div>
    </TabContent>
  );
};

export default ProjectConfig;
