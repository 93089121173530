import {
  domoInstance,
  Button,
  BUTTON_COLORS,
  BUTTON_SIZES,
  INPUT_SIZES,
  Icon,
  Input,
} from '@acmos/ui';
import { DomoModels } from '@acmos/models';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { i18nContext } from '../../../i18n';

import ActivitiesList from '../Activities/ActivitiesList';
import Discussion from '../components/Discussion/Discussion';
import EditableEntityProp from '../components/EditableEntityProp';
import EditableFieldWithModal, {
  extractTextFromHtml,
} from '../components/EditableFieldWithModal/EditableFieldWithModal';
import Importance from '../components/Importance';
import { ProjectContext } from '../Project';
import styles from './Requirement.module.scss';

const getChildrenExpandedValue = (requirement) => {
  return JSON.parse(localStorage.getItem('children-expanded-' + requirement.id) || 'true');
};
const setChildrenExpandedValue = (requirement, expanded) => {
  return localStorage.setItem('children-expanded-' + requirement.id, expanded?.toString());
};

// TODO - Instead of saving everytime, create a logic
// where it stores the last update and checks if the local is
// dirty or not. If yes, will not update the info, if no, updates the info
const RequirementRow = ({
  auth,
  addRequirement,
  searchText,
  requirement,
  moveLeft,
  moveRight,
  parentNumber,
  index,
  columnsState,
  numberWidth = 50,
}) => {
  const refs = {
    main: useRef(),
    detail: useRef(),
    scrollTimeout: useRef(),
    mainRect: useRef(),
    detailRect: useRef(),
  };
  const [visibilityState, setVisibilityState] = useState({ visible: false, height: 0 });
  const [childrenVisible, _setChildrenVisible] = useState(getChildrenExpandedValue(requirement));
  const [isOpen, setIsOpen] = useState(false);
  const visibilityControlRef = useRef();
  const [activities, setActivities] = useState([]);
  const [descriptionVisible, setDescriptionVisible] = useState(false);
  const authStore = auth.useAuthStore((s) => s);
  const saveRequirementDebounce = useRef();
  const { i18n } = useContext(i18nContext);
  const { project, phases, requirements } = useContext(ProjectContext);
  const requirementRenderEvent = new CustomEvent('requirementrender', {
    bubbles: true,
    detail: { origin: requirement.id },
  });
  useEffect(() => {
    clearTimeout(refs.scrollTimeout.current);
    const event = (e) => {
      clearTimeout(refs.scrollTimeout.current);
      refs.scrollTimeout.current = setTimeout(() => {
        let mainRect = refs.main.current?.getBoundingClientRect();
        let detailRect = refs.detail.current?.getBoundingClientRect() || { height: 0 };

        if (isNaN(mainRect?.height)) return;
        let bufferSize = 500;
        let top = mainRect.top;
        let height = mainRect.height + detailRect.height;

        if (top > -bufferSize && top < window.innerHeight + bufferSize) {
          if (!visibilityState.visible) {
            visibilityState.visible = true; //SetState might be slower than next scroll ping
            setVisibilityState({ visible: true, height });
          }
        } else {
          if (visibilityState.visible) {
            visibilityState.visible = false; //SetState might be slower than next scroll ping
            setVisibilityState({
              visible: false,
              height,
              top,
              total: window.scrollY + window.innerHeight,
              scrollY: window.scrollY,
              innerHeight: window.innerHeight,
            });
          }
        }
      }, 50);
    };
    event();
    setTimeout(() => event(), 200);
    window.addEventListener('scroll', event);
    window.addEventListener('requirementrender', event);
    return () => {
      window.removeEventListener('scroll', event);
      window.removeEventListener('requirementrender', event);
    };
  }, [
    searchText,
    refs.detail,
    refs.main,
    visibilityState,
    refs.scrollTimeout,
    isOpen,
    childrenVisible,
    requirement.parentId,
  ]);

  useEffect(() => {
    if (
      visibilityControlRef.current?.isOpen !== isOpen ||
      visibilityControlRef.current?.childrenVisible !== childrenVisible
    ) {
      visibilityControlRef.current = { isOpen: isOpen, childrenVisible: childrenVisible };
      window.dispatchEvent(requirementRenderEvent);
    }
  }, [isOpen, childrenVisible, visibilityControlRef]);

  const setChildrenVisible = (visible) => {
    _setChildrenVisible(visible);
    setChildrenExpandedValue(requirement, visible);
  };

  useEffect(() => {
    if (!isOpen) return;

    return DomoModels.Activity.subscribeAll(
      domoInstance.db,
      [['requirementId', '==', requirement.id]],
      (list) => {
        setActivities(
          list.sort((a, b) => {
            if (a.number > b.number) return 1;
            if (a.number < b.number) return -1;
            return 0;
          })
        );
      }
    );
  }, [isOpen, requirement.id]);

  const remove = () => (e) => {
    requirements.forEach((child) => {
      if (child.parentId !== requirement.id) return;
      child.parentId = requirement.parentId;
      child.save(domoInstance.db);
    });

    requirement.delete(domoInstance.db);
  };

  const requirementChange = (prop, valueTreatment) => (e) => {
    valueTreatment = valueTreatment ? valueTreatment : (o) => o;
    requirement[prop] = valueTreatment(
      typeof e === 'string' ? e : e?.target ? e.target.value || e.target?.innerHTML : e
    );
    console.log('requirement[prop]', requirement[prop]);
    clearTimeout(saveRequirementDebounce.current);
    saveRequirementDebounce.current = setTimeout(() => {
      requirement.save(domoInstance.db);
    }, 2000);
  };

  const onFocus = (prop) => (e) => {
    if (!requirement.touching[prop]) {
      requirement.touching[prop] = {};
    }
    requirement.touching[prop][authStore.currentUser.uid] = {
      user: {
        uid: authStore.currentUser.uid,
        displayName: authStore.currentUser.displayName,
        email: authStore.currentUser.email,
      },
    };
    requirement.save(domoInstance.db);
  };
  const onBlur = (prop) => (e) => {
    if (!requirement.touching[prop]) {
      requirement.touching[prop] = [];
    }
    delete requirement.touching[prop][authStore.currentUser.uid];
    requirement.save(domoInstance.db);
  };

  const children = useMemo(() => {
    return requirements.filter((o) => o.parentId === requirement.id);
  }, [requirement.id, requirements]);

  const childrenEstimate = useMemo(() => {
    const getEstimate = (list, req) => {
      let estimate = 0;

      let children = list.filter((current) => {
        if (current.parentId === req.id) {
          estimate += parseInt(getEstimate(list, current)) || 0;
        }
        return current.parentId === req.id;
      });

      return children?.length > 0 ? estimate : req.estimate || 0;
    };
    return getEstimate(requirements, requirement);
  }, [requirements, requirement]);

  if (!searchText) {
    requirement.number = [parentNumber, index + 1].filter((o) => o).join('.');
  }

  const childrenRender =
    childrenVisible &&
    !searchText &&
    children.map((item, i) => {
      return (
        <RequirementRow
          key={item.id}
          searchText={searchText}
          auth={auth}
          addRequirement={addRequirement}
          requirement={item}
          moveLeft={moveLeft}
          moveRight={moveRight}
          parentNumber={requirement.number}
          numberWidth={numberWidth}
          columnsState={columnsState}
          index={i}
        />
      );
    });

  if (!visibilityState.visible) {
    return (
      <>
        <div
          ref={refs.main}
          className={[styles.Fake, styles.RequirementMain, isOpen ? styles.Opened : ''].join(' ')}
          style={{ height: visibilityState.height || 52 }}></div>
        {childrenRender}
      </>
    );
  }

  requirement.title = extractTextFromHtml(requirement.title);

  return (
    <>
      <div
        ref={refs.main}
        className={[styles.RequirementMain, isOpen ? styles.Opened : ''].join(' ')}>
        <div
          className={[styles.Requirement, isOpen ? styles.Opened : ''].join(' ')}
          key={requirement.id}>
          <div className={styles.Toggler}>
            <div className={styles.ToggleContainer}>
              <div
                className={styles.PanelToggler}
                onClick={() => {
                  setIsOpen(!isOpen);
                }}>
                <Icon icon="angle-down" />
              </div>
              {children.length > 0 && (
                <div
                  className={styles.ChildrenToggler}
                  onClick={() => setChildrenVisible(!childrenVisible)}>
                  <Icon icon={childrenVisible ? 'minus' : 'plus'} />
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              width: numberWidth,
              minWidth: numberWidth,
            }}
            className={[
              styles.NumberContainer,
              styles['Level-' + requirement.number.split('.').length],
              columnsState['project.requirement.number']?.closed ? styles.ColumnClosed : '',
            ].join(' ')}>
            <div className={styles.NumberContent}>
              <div
                className={styles.BackNumber}
                onClick={requirement.parentId ? moveLeft(requirement, index) : () => {}}>
                {!!requirement.parentId && <Icon icon="arrow-right-to-bracket" />}
              </div>
              <div className={styles.Number}>
                {requirement.number
                  .toString()
                  .split('.')
                  .map((part, ii) => {
                    return (
                      <div key={part + '-' + ii} className={styles.NumberPart}>
                        {part}
                      </div>
                    );
                  })
                  .reduce((prev, current) => {
                    return [prev, <div className={styles.NumberDot}>.</div>, current];
                  })}
              </div>
              {index > 0 && (
                <div className={styles.GetParent} onClick={moveRight(requirement, index)}>
                  <Icon icon="arrow-right-to-bracket" />
                </div>
              )}
            </div>
            <div className={styles.AddBellow} onClick={() => addRequirement(requirement)}>
              <Icon icon="plus" />
            </div>
            <div className={styles.DisplayClosed}>{requirement.number.toString()}</div>
          </div>
          <div
            className={[
              styles.CellForEditing,
              styles.Title,

              columnsState['project.requirement.title']?.closed ? styles.ColumnClosed : '',
            ].join(' ')}>
            <Input
              onFocus={onFocus('title')}
              onBlur={onBlur('title')}
              value={requirement.title}
              onChange={requirementChange('title')}
              containerExtraClass={[
                styles['Level-Title-' + requirement.number.split('.').length],
                styles.Input,
              ].join(' ')}
            />
            {/* <div className={styles.DisplayClosed}>{requirement.title}</div> */}
          </div>
          <div
            className={[
              styles.CellForEditing,
              styles.Description,
              columnsState['project.requirement.description']?.closed ? styles.ColumnClosed : '',
            ].join(' ')}
            onClick={() => setDescriptionVisible(true)}>
            <EditableFieldWithModal
              visible={descriptionVisible}
              auth={auth}
              entity={requirement}
              attribute={'description'}
              entityChange={requirementChange('description')}
              onClose={() => setDescriptionVisible(false)}
              title={`${requirement.number} - ${requirement.title || 'Sem título ainda'}`}
              description="Descrição do Requerimento"
            />
            {/* <div className={styles.DisplayClosed}>
              <div className={styles.Content}>{requirement.description}</div>
            </div> */}
          </div>
          <div
            className={[
              styles.SelectCell,
              styles.Small,
              styles.Status,

              columnsState['project.requirement.status']?.closed ? styles.ColumnClosed : '',
            ].join(' ')}>
            <Input
              items={[
                { name: 'project.requirement.status.draft' },
                { name: 'project.requirement.status.waitingApproval' },
                { name: 'project.requirement.status.approved' },
                { name: 'project.requirement.status.waitingFix' },
                { name: 'project.requirement.status.rejected' },
                { name: 'project.requirement.status.completed' },
              ]}
              selectedItemProp={'name'}
              itemDisplayProps={(item) => {
                if (!item) return '';
                return i18n(item.name);
              }}
              value={requirement.status}
              onChange={requirementChange('status')}
              containerExtraClass={styles.Select}
              size={INPUT_SIZES.SMALL}
              autoComplete="off"
            />
          </div>
          <div
            className={[
              styles.SelectCell,
              styles.Small,
              styles.Phase,
              columnsState['project.requirement.phase']?.closed ? styles.ColumnClosed : '',
            ].join(' ')}>
            <Input
              items={phases}
              itemDisplayProps={(value) => {
                return value?.title;
              }}
              value={requirement.phase}
              selectedItemProp={'id'}
              onChange={requirementChange('phase')}
              containerExtraClass={styles.Select}
              size={INPUT_SIZES.SMALL}
              autoComplete="off"
            />
          </div>
          <div
            className={[
              styles.InputCell,
              styles.Small,
              styles.Estimate,
              columnsState['project.requirement.estimate']?.closed ? styles.ColumnClosed : '',
            ].join(' ')}>
            {children?.length > 0 ? (
              <div className={styles.ChildrenEstimate}>
                <div className={styles.Text}>{childrenEstimate}</div>
                <Icon
                  icon="arrow-down"
                  data-tooltip={i18n('project.requirement.childrenEstimateSum')}
                />
              </div>
            ) : (
              <Input
                value={requirement.estimate}
                interceptValueChange={(value) => {
                  return value.replace(/[^\d]/gi, '');
                }}
                onChange={requirementChange('estimate')}
                containerExtraClass={[styles.Input].join(' ')}
                size={INPUT_SIZES.SMALL}
                autoComplete="off"
              />
            )}
          </div>
          {/* <td className={styles.Small,styles.Tags}>Tags</td> */}
          <div
            className={[
              styles.Cell,
              styles.Importance,
              columnsState['project.requirement.importance']?.closed ? styles.ColumnClosed : '',
            ].join(' ')}>
            <Importance entity={requirement} />
            <div className={styles.DisplayClosed}>{requirement.importance}</div>
          </div>
          <div className={[styles.Cell, styles.ActionsCell].join(' ')}>
            <div className={styles.Actions}>
              <Button round leftIcon="trash" onClick={remove()} color={BUTTON_COLORS.RED}></Button>
            </div>
          </div>
        </div>

        {isOpen && (
          <div
            ref={refs.detail}
            className={[styles.AdvancedContent, isOpen ? styles.Opened : ''].join(' ')}
            key={requirement.id + 'extra'}>
            <div className={styles.AdvancedContentMain}>
              <div className={styles.Activities}>
                <ActivitiesList
                  auth={auth}
                  project={project}
                  requirement={requirement}
                  activities={activities}
                />
              </div>
              <div className={styles.Discussion}>
                <Discussion auth={auth} entity={requirement} />
              </div>
            </div>
          </div>
        )}
      </div>

      {childrenRender}
    </>
  );
};
export default RequirementRow;
