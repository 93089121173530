import { initializeApp } from 'firebase/app';
import { getFirestore, collection } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyD3jbaIuENHNr_bmXeP-8S2EXc6x_bWkzA',
  authDomain: 'acmoscloud.firebaseapp.com',
  projectId: 'acmoscloud',
  storageBucket: 'acmoscloud.appspot.com',
  messagingSenderId: '856535356491',
  appId: '1:856535356491:web:9b9ca26c4e10b1ecb129ce',
  measurementId: 'G-FE8FCSCWXN',
};

const app = initializeApp(firebaseConfig);

const instance = {
  app,
  db: getFirestore(app),
  functions: getFunctions(app),
};
if (typeof window !== undefined) {
  if (window.location.hostname === 'localhost') {
    connectFunctionsEmulator(instance.functions, 'localhost', 5001);
  }
}
export default instance;
