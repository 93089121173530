import PropTypes from 'prop-types';
import React, { Fragment, useMemo, useState } from 'react';
import Auth from '../../screens/Auth/Auth';

const PrivateScreen = ({ isAuthenticated, app, auth, ScreenComponent }) => {
  return isAuthenticated ? (
    <ScreenComponent auth={auth} />
  ) : (
    <Auth auth={auth} appName={app} />
  );
};

PrivateScreen.propTypes = {};

export default PrivateScreen;
