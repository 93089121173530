import React, { useRef, useEffect } from 'react';

export const useDragAndDrop = () => {
  const dragging = useRef({
    elements: [],
  });
  const fakeElements = useRef([]);

  const getSiblingsConfig = (element, sortingOptions) => {
    let siblingConfig = [];
    if (sortingOptions.droppableRefs) {
      sortingOptions.droppableRefs.map((ref) => {
        siblingConfig.push({
          main: ref.current,
          children: Array.from(ref.current.children).map((element) => {
            return {
              element,
              rect: element.getBoundingClientRect(),
            };
          }),
        });
      });
    } else {
      siblingConfig.push({
        main: element.parentElement,
        children: Array.from(element.parentElement.children).map((element) => {
          return {
            element,
            rect: element.getBoundingClientRect(),
          };
        }),
      });
    }
    return siblingConfig;
  };

  const dragEnd = () => {
    sorting_dragEnd();
    dragging.current.elements.forEach((config) => {
      config.element.style.display = config.element.dataset.display;
      if (config.sortingOptions && config.dropabble) {
        config.dropabble.main.classList.remove(
          config.sortingOptions.droppableHoverClass,
        );
        if (!config.sortingOptions.preventDomUpdateOnItem) {
          if (
            config.dropabble.children.length &&
            config.currentSibling &&
            config.dropabble.main.contains(config.currentSibling.element)
          ) {
            config.dropabble.main.insertBefore(
              config.element,
              config.isAfter
                ? config.currentSibling.element.nextSibling
                : config.currentSibling.element,
            );
          } else if (config.dropabble) {
            config.dropabble.main.appendChild(config.element);
          }
        }
      }
      config.onDragEnd?.({
        newIndex: config.newIndex,
        top: config.top,
        left: config.left,
        droppable: config.dropabble.main,
        droppableIndex: config?.sortingOptions?.droppableRefs?.findIndex(
          (ref) => ref.current === config.dropabble.main,
        ),
      });
    });
    dragging.current.elements.splice(0, dragging.current.elements.length);
  };
  const sorting_dragEnd = () => {
    fakeElements.current.forEach((config) => {
      try {
        config.copy.remove();
      } catch {}
    });
    setTimeout(() => {
      Array.from(
        document.body.querySelectorAll('.acmos-drag-placeholder'),
      ).forEach((a) => a.remove());
    });

    fakeElements.current.splice(0, fakeElements.current.length);
    document.body.classList.remove('no-select');
  };

  const animatePlaceholder = (placeholder, height) => {
    // placeholder.style.height = '0px';
    // setTimeout(() => {
    //   placeholder.style.height = height;
    // }, 10);
  };

  useEffect(() => {
    const sorting_mousemove = (e) => {
      fakeElements.current.forEach((config, i) => {
        const draggingItem = dragging.current.elements[i];
        if (!draggingItem?.sortingOptions) return;
        let currentIndex = 0;
        config.siblingConfig.find((dropabble, sa) => {
          return dropabble.children.find((o, i) => {
            currentIndex++;
            return o.element === draggingItem.element;
          })
            ? dropabble
            : false;
        });
        clearTimeout(config.timeout);
        config.timeout = setTimeout(() => {
          let top = parseInt(e.clientY - draggingItem.initial.relative.y);
          let left = parseInt(e.clientX - draggingItem.initial.relative.x);
          draggingItem.top = top;
          draggingItem.left = left;

          config.copy.style.top = top + 'px';
          config.copy.style.left = left + 'px';

          config.siblingConfig.find((dropabble, si) => {
            let newIndex = -1;

            let droppableRect = dropabble.main.getBoundingClientRect();
            if (
              droppableRect.top <= top &&
              droppableRect.top + droppableRect.height >= top &&
              droppableRect.left <= left &&
              droppableRect.left + droppableRect.width >= left
            ) {
              if (draggingItem.sortingOptions.droppableHoverClass) {
                dropabble.main.classList.add(
                  draggingItem.sortingOptions.droppableHoverClass,
                );
              }
              draggingItem.dropabble = dropabble;
            } else {
              if (draggingItem.sortingOptions.droppableHoverClass) {
                dropabble.main.classList.remove(
                  draggingItem.sortingOptions.droppableHoverClass,
                );
              }
            }

            let sibling = dropabble.children.find((info, index) => {
              newIndex = index;

              if (
                info.rect.top <= top &&
                info.rect.top + info.rect.height >= top &&
                info.rect.left <= left &&
                info.rect.left + info.rect.width >= left
              ) {
                return info;
              }
              return false;
            });
            if (
              !sibling &&
              draggingItem.dropabble &&
              draggingItem.dropabble.main !== config.placeholder.parentElement
            ) {
              draggingItem.dropabble.main.appendChild(config.placeholder);
              animatePlaceholder(
                config.placeholder,
                draggingItem.initial.height + 'px',
              );
              return dropabble;
            } else if (sibling && draggingItem.currentSibling !== sibling) {
              draggingItem.isAfter = newIndex >= currentIndex;
              draggingItem.currentSibling = sibling;
              draggingItem.newIndex = newIndex;

              draggingItem.dropabble.main.insertBefore(
                config.placeholder,
                draggingItem.isAfter
                  ? sibling.element.nextSibling
                  : sibling.element,
              );
              animatePlaceholder(
                config.placeholder,
                draggingItem.initial.height + 'px',
              );

              return dropabble;
            }
          });
        }, 1); //performance technique ;)
      });
    };

    const default_mousemove = (e) => {
      dragging.current.elements.forEach((config, i) => {
        if (config.sortingOptions) return;
        clearTimeout(config.timeout);
        config.timeout = setTimeout(() => {
          let top = parseInt(e.clientY - config.initial.relative.y);
          let left = parseInt(e.clientX - config.initial.relative.x);
          config.element.style.top = top + 'px';
          config.element.style.left = left + 'px';
          config.top = top;
          config.left = left;
        });
      });
    };

    const mousemove = (e) => {
      sorting_mousemove(e);
      default_mousemove(e);
    };
    const mouseup = () => {
      dragEnd();
    };

    document.addEventListener('mouseup', mouseup);
    document.addEventListener('mousemove', mousemove);
    return () => {
      document.removeEventListener('mouseup', mouseup);
      document.removeEventListener('mousemove', mousemove);
    };
  }, []);

  const createDraggable = ({
    onDragEnd,
    onMouseDown,

    gripSelector,
    sortingOptions,
  } = {}) => {
    return {
      onMouseDown: function (e) {
        document.body.classList.add('no-select');
        let element = e.currentTarget;

        if (gripSelector) {
          const grip = element.querySelector(`.${gripSelector}`);
          console.log('grip', grip);
          if (!e.nativeEvent.path.find((o) => o === grip)) return;
        }

        let rect = element.getBoundingClientRect();
        dragging.current.elements.push({
          element,
          initial: {
            width: rect.width,
            height: rect.height,
            relative: {
              y: e.clientY - rect.top,
              x: e.clientX - rect.left,
            },
            offsetX: e.offsetX,
            offsetY: e.offsetY,
            pageX: e.pageX,
            pageY: e.pageY,
            clientX: e.clientX,
            clientY: e.clientY,
          },
          onDragEnd,
          onMouseDown,
          sortingOptions,
        });
        onMouseDown?.(e);

        if (!sortingOptions) {
          return;
        }
        const siblingConfig = getSiblingsConfig(element, sortingOptions);

        element.dataset.display = element.style.display;

        const copy = element.cloneNode(true);
        copy.style.width = rect.width + 'px';
        copy.style.height = rect.height + 'px';
        const placeholder = copy.cloneNode();
        placeholder.style.height = '0px';
        placeholder.classList.add('acmos-drag-placeholder');
        setTimeout(() => {
          placeholder.style.height = rect.height + 'px';
        }, 1);
        if (sortingOptions.placeholderClass) {
          placeholder.classList.add(sortingOptions.placeholderClass);
        } else {
          placeholder.style.background = '#dde';
        }

        copy.style.position = 'fixed';
        copy.style.zIndex = '10000';
        copy.style.top = parseInt(rect.top) + 'px';
        copy.style.left = parseInt(rect.left) + 'px';
        fakeElements.current.push({
          copy,
          placeholder,
          siblingConfig,
        });

        element.style.display = 'none';
        element.parentElement.insertBefore(placeholder, element.nextSibling);
        element.parentElement.insertBefore(copy, element.nextSibling);
      },
    };
  };

  return {
    createDraggable,
  };
};
