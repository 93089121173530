import React from 'react';
import styles from './Loading.scss';

const Loading = ({}) => {
  return (
    <div className={styles.Container}>
      <div className={styles.Spinner}></div>
    </div>
  );
};

export default Loading;
