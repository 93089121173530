import {
  Toast,
  AppBar,
  PrivateScreen,
  Input,
  TightSpace,
  FlexSpace,
  authService,
  APPS,
  cloudInstance,
} from '@acmos/ui';

import { Routes, Route } from 'react-router-dom';
import Project from './screens/Project/Project';
import Home from './screens/Home/Home';
import '@acmos/ui/src/assets/layout/_default.scss';
import styles from './App.module.scss';
import { ReactComponent as Logo } from '@acmos/ui/src/assets/images/domo/logo-h-1.svg';

import {
  browserLanguage,
  format,
  getLanguageJSON,
  i18n,
  languagesList,
  setCurrentLanguage,
} from './i18n';
import React, { useEffect, useState } from 'react';
import { i18nContext } from './i18n';
import { CloudModels } from '@acmos/models';

const auth = authService;
auth.checkAuthState();

export const ToastContext = React.createContext({ toasts: [], addToast: () => {} });

function App() {
  const [companies, setCompanies] = useState([]);
  const [_currentLanguage, _setCurrentLanguage] = useState(browserLanguage?.toLowerCase());
  const [_currentLanguageJSON, _setCurrentLanguageJSON] = useState(
    getLanguageJSON(_currentLanguage)
  );
  const [toasts, setToasts] = useState([]);
  const authStore = authService.useAuthStore((s) => s);
  const currentUser = authStore.currentUser;

  useEffect(() => {
    if (!currentUser?.uid) {
      setCompanies([]);
      return;
    }
    CloudModels.Company.fetchAll(cloudInstance.db, [
      ['id', 'in', Object.keys(currentUser.companies)],
    ]).then((list) => {
      if (!authStore.currentCompany) {
        authStore.setCurrentCompany(list[0]);
      }
      setCompanies(list.sortByKey((o) => [o.name]));
    });
  }, [currentUser?.uid]);

  const addToast = (content, timeout) => {
    const toast = {
      content,
      timeout: -1,
      stopTimeout: () => {
        clearTimeout(toast.timeout);
      },
      startTimeout: () => {
        toast.stopTimeout();
        toast.timeout = setTimeout(() => {
          let index = toasts.findIndex((o) => o === content);
          if (index) {
            toasts.splice(index, 1);
            setToasts([...toasts]);
          }
        }, timeout || 10000);
      },
    };
    // toast.startTimeout();
    toasts.push(toast);
    setToasts([...toasts]);
  };

  console.log('AUTH STORE', authStore.currentCompany?.id, companies);
  return (
    <i18nContext.Provider
      value={{
        i18n: i18n(_currentLanguageJSON),
        format: format(_currentLanguageJSON),
      }}>
      <ToastContext.Provider value={{ toasts, addToast }}>
        <div className={styles.App}>
          {!!currentUser?.uid && (
            <AppBar auth={auth} logo={<Logo />} homePageLink={'/'}>
              <TightSpace />
              <Input
                value={_currentLanguage}
                onChange={(id) => {
                  _setCurrentLanguage(id);
                  _setCurrentLanguageJSON(setCurrentLanguage(id));
                }}
                selectedItemProp={'id'}
                items={languagesList}
                itemDisplayProps={['name']}
                itemRender={(item) => {
                  return (
                    <div>
                      {item.name} <FlexSpace />
                      {item.id}
                    </div>
                  );
                }}
                containerExtraClass={styles.LanguageSelector}
              />
              <Input
                value={authStore.currentCompany?.id}
                onChange={(id) => {
                  authStore.setCurrentCompany(companies.find((c) => c.id === id));
                }}
                selectedItemProp={'id'}
                items={companies}
                itemDisplayProps={['name']}
                containerExtraClass={styles.CompanySelect}
              />
            </AppBar>
          )}

          <Routes>
            <Route
              path="/project/:tab/:id"
              element={
                <PrivateScreen
                  ScreenComponent={Project}
                  isAuthenticated={!!currentUser?.uid}
                  auth={auth}
                  app={APPS.DOMO}
                />
              }
            />
            <Route
              path="/"
              element={
                <PrivateScreen
                  ScreenComponent={Home}
                  isAuthenticated={!!currentUser?.uid}
                  auth={auth}
                  app={APPS.DOMO}
                />
              }
            />
          </Routes>
        </div>
        {toasts.map((toast) => {
          return (
            <Toast onMouseEnter={toast.stopTimeout} onMouseLeave={toast.startTimeout}>
              {toast.content}
            </Toast>
          );
        })}
      </ToastContext.Provider>
    </i18nContext.Provider>
  );
}

export default App;
